import React from "react";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { auth } from "../../firestoreConfig";
import { FiLogOut } from "react-icons/fi";
import LogoutModal from "../../Components/Dashboard/Modals/LogoutModal";

const Navbar = () => {
  const [user] = useAuthState(auth);
  
  return (
    <div className="navbar bg-slate-100">
      <div className="flex-1">
        <p className=" mx-3 font-bold text-xl">Admin Dashboard</p>
      </div>
      <div className="flex-none">
        <label
          className="btn btn-square btn-ghost lg:hidden"
          htmlFor="my-drawer-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-5 h-5 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </label>
      </div>

      {/* sign out */}

      <div className="text-xl font-medium">
        {user ? (
          <label htmlFor="confirmation-logout">
            <p
              className="flex items-center gap-2 mr-4 cursor-pointer"
            >
              {" "}
              <FiLogOut /> <span className="hidden md:block text-lg">Log Out </span>
            </p>
          </label>
        ) : (
          <Link to="/admin">Login</Link>
        )}
      </div>
      <LogoutModal />
    </div>
  );
};

export default Navbar;
