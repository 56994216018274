import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useContext, useEffect } from "react";
import { projectContext } from "../../Context/projectContext";
import Payment from "../Paypal Payment/Payment";
import OrderSummary from "../Summary/OrderSummary";

const Checkout = () => {
  const { setCustomerOrder, finalData, setFinalData } =
    useContext(projectContext);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("orders"))) {
      setFinalData(JSON.parse(sessionStorage.getItem("orders")));
    }
  }, [setCustomerOrder, setFinalData]);

  return (
    <div className="overflow-y-hidden bg-white">
      <div className="w-full lg:py-16 md:py-12 py-9 px-4 md:px-6 lg:px-20 w-full">
        <div className="flex w-full flex-col md:flex-row justify-center items-center order-last gap-6 mx-auto">
          <div className="flex w-full flex-col justify-start xl:items-start">
            <div id="paymentMethod">
              <p className="text-3xl xl:text-4xl font-semibold leading-7 xl:leading-9 text-gray-800 font-Lustria text-black">
                Payment Method
              </p>
            </div>
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "GBP",
              }}
            >
              <Payment />
            </PayPalScriptProvider>
          </div>
          <OrderSummary order={finalData} />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
