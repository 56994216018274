import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { projectContext } from "../../Context/projectContext";
import { selectedFinalOrder, editedOrder } from "../../Helpers/orderFunctions";
import { toast } from "react-toastify";

const RedirectButtons = ({ step, redirect }) => {
  const { finalData, setFinalData, newCustom, calculationPriceData } =
    useContext(projectContext);

  return (
    <div className="flex justify-end my-6 gap-4">
      {step.length <= 1 ? (
        ""
      ) : (
        <Link
          className={`text-black px-[22px] p-y-1.5 border-2 border-black rounded-3xl bg-[#A2C397] text-[#121212] text-xl flex justify-center items-center ${
            step.length >= 5 ? "hidden" : ""
          }`}
          to={`/step-${step.length - 1}`}
          onClick={() => window.scrollTo(0, 0)}
        >
          Back
        </Link>
      )}

      {redirect ? (
        <Link
          className={`text-black px-[22px] p-y-1.5 border-2 border-black rounded-3xl bg-[#A2C397] text-[#121212] text-xl ${
            step.length >= 4 ? "hidden" : ""
          }`}
          to={`/step-${step.length + 1}`}
          onClick={() => window.scrollTo(0, 0)}
        >
          Next
        </Link>
      ) : (
        <Link
          className={`text-black px-[22px] p-y-1.5 border-2 border-black rounded-3xl bg-[#D1DDD0CC] text-[#121212] cursor-not-allowed text-xl ${
            step.length >= 4 ? "hidden" : ""
          }`}
          onClick={() => {
            window.scrollTo(0, 0);
            if (!newCustom.shape) {
              toast.error("Please select a custom shape!", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else if (!newCustom.filling) {
              toast.error("Please select a custom filling!", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else if (
              !(
                parseFloat(newCustom.dimensions.width) >= 15 &&
                parseFloat(newCustom.dimensions.width) <= 120
              ) ||
              !(
                parseFloat(newCustom.dimensions.length) >= 15 &&
                parseFloat(newCustom.dimensions.length) <= 200
              )
            ) {
              toast.error("Width - Min: 15cm, Max: 120cm! Length - Min: 15cm, Max: 200cm!", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          }}
        >
          Next
        </Link>
      )}

      {step.length === 4 && redirect ? (
        <Link
          onClick={
            newCustom.order
              ? () =>
                  editedOrder(
                    newCustom,
                    newCustom.order,
                    finalData,
                    setFinalData,
                    calculationPriceData
                  )
              : () => selectedFinalOrder(finalData, newCustom, setFinalData)
          }
          className={`text-black px-[22px] p-y-1.5 border-2 border-black rounded-3xl bg-[#A2C397] text-[#121212] text-xl text-center ${
            step.length === 4 ? "" : "hidden"
          }`}
          to={`/overview`}
        >
          {newCustom.order ? "Edit Your Order" : "Add To Basket"}
        </Link>
      ) : (
        <Link
          className={`text-black px-[22px] p-y-1.5 border-2 border-black rounded-3xl bg-[#D1DDD0CC] text-[#121212] cursor-not-allowed text-xl text-center ${
            step.length === 4 ? "" : "hidden"
          }`}
          onClick={() => {
            window.scrollTo(0, 0);
            if (!newCustom.fabric && !newCustom.color) {
              toast.error("Please select a custom fabric and a custom color", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else if (!newCustom.fabric) {
              toast.error("Please select a custom fabric!", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else if (!newCustom.color) {
              toast.error("Please select a custom color!", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          }}
        >
          Add To Basket
        </Link>
      )}
    </div>
  );
};

export default RedirectButtons;
