import React from "react";
import UpdateShapeForm from "../../../Shared/Forms/UpdateShapeForm";

const UpdateShapeModal = ({formData,setFormData, fetchShapes,allShapeLabel}) => {
    return (
    <div>
      <input type="checkbox" id="update-shape-modal-elx" className="modal-toggle" />
      <div className="modal modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-center">Update Shape</h3>
          <UpdateShapeForm
            formData={formData}
            setFormData={setFormData}
            fetchShapes={fetchShapes}
            allShapeLabel={allShapeLabel}
          ></UpdateShapeForm>
        </div>
      </div>
    </div>
  );
};

export default UpdateShapeModal;
