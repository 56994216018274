import React, { useEffect, useRef, useState } from "react";
import m2mApiClass from "../../firestoreBackendAPI";
import { toast } from "react-toastify";
import ConfirmationDelete from "./Modals/ConfirmationDelete";
import ButtonLoading from "../../Shared/ButtonLoading";
import { RiDeleteBin6Line } from "react-icons/ri";

const AddFabrics = () => {
  const [fabricData, setFabricData] = useState([]);
  const [prevFabricData, setPrevFabricData] = useState([]);
  const [fabricObj, setFabricObj] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [storegetFabricsLength, setStoregetFabricsLength] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  let currentfabricsLength = 0;
  const allFabricLabel = [];

  //   from get api......
  const fetchFabric = async () => {
    const allfetchedfabric = await m2mApiClass.getFabricData();
    setFabricData(allfetchedfabric);
    setPrevFabricData(allfetchedfabric);
    setIsLoading(false);
    setStoregetFabricsLength(allfetchedfabric.length);
  };
  useEffect(() => {
    fetchFabric();
  }, []);

  if (prevFabricData.length > 0) {
    prevFabricData.forEach((fabric) => allFabricLabel.push(fabric.name));
  }
  const handleUpdateData = (currentObj, index) => {
    setFabricObj(currentObj);
    setCurrentIndex(index);
  };

  const handleAddFabric = (e) => {
    e.preventDefault();

    setFabricData([...fabricData, { name: "", fabricURL: "", id: "" }]);
    setIsButtonDisabled(false);
  };

  const handleFabricChange = (value, indexValue, fieldName) => {
    let updatedObjectIndex = fabricData.findIndex(
      (obj, index) => index === indexValue
    );

    if (fieldName === "fabric") {
      setFabricData([
        ...fabricData.slice(0, updatedObjectIndex),
        {
          ...fabricData[updatedObjectIndex],
          name: value,
          id: value.trim().includes(" ")
            ? value.toLowerCase().split(" ").join("-")
            : value.toLowerCase(),
        },
        ...fabricData.slice(updatedObjectIndex + 1),
      ]);
    } else {
      setFabricData([
        ...fabricData.slice(0, updatedObjectIndex),
        {
          ...fabricData[updatedObjectIndex],
          fabricURL: value,
        },
        ...fabricData.slice(updatedObjectIndex + 1),
      ]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    currentfabricsLength = fabricData.length - storegetFabricsLength;
    if (currentfabricsLength > 0) {
      let updatedfabricsArray = fabricData.slice(-currentfabricsLength);
      updatedfabricsArray.forEach((eachfabric) => {
        if (
          allFabricLabel.find(
            (label) => label.toLowerCase() === eachfabric.name.toLowerCase()
          )
        ) {
          toast.error(`${eachfabric.name} Fabric Already Exist! Change the Name!`);
          return;
        }

        try {
          m2mApiClass.addFabricData(eachfabric);
          toast(`${eachfabric.name} is Added`);
          fetchFabric();

        } catch (error) {
          toast.error("Error While Adding Fabric");
        } finally {
          setIsButtonDisabled(true);
        }
      });
    } else {
      toast.error("Nothing to add");
      setIsButtonDisabled(true);
    }
  };
  return (
    <div>
      <div className="container px-4">
        <div className="container flex flex-col items-center justify-between py-2 mb-4 sm:flex-row">
          <h2 className="font-bold text-2xl mb-5  text-black p-5">
            Add Fabrics
          </h2>
        </div>

        {/* input form */}
        {isLoading ? (
          <ButtonLoading />
        ) : (
          <form
            onSubmit={handleSubmit}
            id="addfabricform-elx"
            className="mx-auto flex flex-col justify-center "
          >
            {fabricData?.map((fabric, index) => (
              <div
                key={fabric._id}
                className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 gap-y-5"
              >
                <input
                  type="text"
                  placeholder="Add Fabric Type"
                  value={fabric.name}
                  required
                  index={index}
                  onChange={(e) =>
                    handleFabricChange(e.target.value, index, "fabric")
                  }
                  className="input input-bordered w-full sm:max-w-xs text-xl"
                  disabled={typeof fabric._id != "undefined" && true}
                ></input>
                <input
                  type="text"
                  name=""
                  required
                  placeholder="Add Image Link"
                  value={fabric.fabricURL}
                  index={index}
                  onChange={(e) =>
                    handleFabricChange(e.target.value, index, "fabricURL")
                  }
                  className="input input-bordered w-full sm:max-w-xs"
                  disabled={typeof fabric._id != "undefined" && true}
                />
                <label htmlFor="confirmation-delete">
                  <RiDeleteBin6Line
                    className="object-cover ml-2 sm:mt-2 w-6 h-6 cursor-pointer"
                    onClick={() => handleUpdateData(fabric, index)}
                  ></RiDeleteBin6Line>
                </label>
              </div>
            ))}

            <div className="inline-block mx-auto">
              <button
                className="btn my-7 mx-auto min-w-[7.5rem] mr-5"
                onClick={handleAddFabric}
              >
                Add Fabric
              </button>
              <button
                type="submit"
                disabled={isButtonDisabled}
                className="btn my-7 mx-auto min-w-[7.5rem]"
              >
                Save
              </button>
            </div>
          </form>
        )}

        {/* <ConfirmationModalFabrics currentData= {fabricObj} fetchFabric={fetchFabric}></ConfirmationModalFabrics> */}

        <ConfirmationDelete
          currentData={fabricObj}
          allFetchedData={fetchFabric}
          apiMethod={m2mApiClass.deleteFabricData}
          index={currentIndex}
          optionalArrayofObject={fabricData}
          optionalArrayofObjectFunc={setFabricData}
        ></ConfirmationDelete>
      </div>
    </div>
  );
};

export default AddFabrics;
