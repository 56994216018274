import React, { useContext, useEffect, useState } from "react";
import Summary from "../Summary/Steppers";
import RedirectButtons from "../Reuseable/RedirectButtons";
import DimensionDetails from "./DimensionDetails";
import { useNavigate } from "react-router-dom";
import { projectContext } from "../../Context/projectContext";
import { calculationPrice } from "../../Helpers/orderFunctions";
import m2mApiClass from "../../firestoreBackendAPI";
import Loading from "../../Shared/Loading";

const RequiredDimensions = ({ step, keyValue }) => {
  const {
    shapeData,
    newCustom,
    setNewCustom,
    setCalculationPriceData,
    setIsLoading,
    isLoading,
  } = useContext(projectContext);
  let dimensionsData = shapeData.filter((data) => data.id === newCustom.shape);

  const [redirect, setRedirect] = useState(false);
  let navigate = useNavigate();

  const fetchData = async () => {
    const allfetchedPrice = await m2mApiClass.getPriceData();
    setCalculationPriceData(allfetchedPrice);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (
      parseFloat(newCustom.dimensions.width) >= 15 &&
      parseFloat(newCustom.dimensions.width) <= 120 &&
      parseFloat(newCustom.dimensions.length) >= 15 &&
      parseFloat(newCustom.dimensions.length) <= 200
    ) {
      setRedirect(true);
    } else {
      setRedirect(false);
    }
    window.addEventListener("load", () => {
      navigate("/step-1");
    });
  }, [newCustom.dimensions.width, newCustom.dimensions.length, navigate]);

  return (
    <div className="max-w-[1440px] mx-auto my-0">
      <div className="block lg:flex">
        <div className="w-full lg:w-[70%] bg-white p-0 xsm:p-5 flex flex-col justify-between">
          <div id={keyValue}>
            <p className="text-xl lg:text-3xl font-normal font-Lustria text-black">
              Enter Your Required Dimensions
            </p>
            <p className="mb-6 text-lg lg:text-xl font-Lustria text-black">
              Step 3
            </p>
            {isLoading ? (
              <Loading />
            ) : (
              <div>
                {dimensionsData.map((data, index) => (
                  <DimensionDetails
                    key={index}
                    data={data}
                    calculationPrice={calculationPrice}
                    setNewCustom={setNewCustom}
                    newCustom={newCustom}
                  />
                ))}
              </div>
            )}
          </div>

          <RedirectButtons
            step={step}
            selectedDataFunction={null}
            redirect={redirect}
            editedOrder={null}
            newCustom={null}
          />
        </div>
        <Summary
          step={step}
          productPrice={newCustom.price}
          data={dimensionsData[0]}
        />
      </div>
    </div>
  );
};

export default RequiredDimensions;
