import React from "react";
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";


const FilteredDatePicker = (props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    endDateInputBoxVisible,
    setEndDateInputBoxVisible,
  } = props;
  const handleStartDate = (date) => {
    setEndDateInputBoxVisible(false);
    if (endDate !== null) {
      setStartDate(date <= endDate ? date : null);
    } else {
      setStartDate(date);
    }
  };

  const handleEndDate = (date) => {
    setEndDate(date >= startDate ? date : null)
  };

  return (
    <div className="sm:mx-4 mb-4 sm:mb-0 flex flex-col">
      <p className="font-bold pb-1">Start Date</p>
      <DatePicker
        selected={startDate}
        onChange={(date) => handleStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="dd/MM/yyyy"
        maxDate={addDays(new Date(), 0)}
        dateFormat="dd/MM/yyyy"
        className="border-gray-300 border rounded-md px-4 py-2 mb-2 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
      />
      <p className="font-bold pb-1">End Date</p>
      <DatePicker
        disabled={endDateInputBoxVisible}
        selected={endDate}
        onChange={(date) => handleEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText="dd/MM/yyyy"
        dateFormat="dd/MM/yyyy"
        maxDate={addDays(new Date(), 0)}
        className={`${
          endDateInputBoxVisible && "bg-slate-200 cursor-not-allowed"
        } border-gray-300 border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent`}
      />
    </div>
  );
};

export default FilteredDatePicker;

