import React, { useContext, useEffect, useState } from "react";
import DetailsCard from "../Reuseable/DetailsCard";
import Summary from "../Summary/Steppers";
import RedirectButtons from "../Reuseable/RedirectButtons";
import Loading from "../../Shared/Loading";
import { projectContext } from "../../Context/projectContext";
import m2mApiClass from "../../firestoreBackendAPI";
import AbandonOrder from "../Abandon Order/AbandonOrder";

const DesiredShape = ({ step, keyValue }) => {
  const { shapeData, setShapeData, newCustom, isLoading,setIsLoading,isStockFull, setIsStockFull } =
    useContext(projectContext);

  const [redirect, setRedirect] = useState(false);
  const [show, setShow] = useState(false);
  let [selectedButton, setSelectedButton] = useState(newCustom.shape);

  const fetchStockFullData = async () => {
    const isStockFullData = await m2mApiClass.getStockData();
    setIsStockFull(isStockFullData);
    if (isStockFullData[0].isStockFull==="false") {
      fetchData();
    }
  };

  const fetchData = async () => {
    const allFetchedShapes = await m2mApiClass.getShapeData();
    setShapeData(allFetchedShapes);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStockFullData();
  }, []);

  useEffect(() => {
    if (!newCustom.shape) {
      setRedirect(false);
    }
    if (newCustom.shape) {
      setRedirect(true);
    }
  }, [newCustom.shape]);

  return (
    <React.Fragment>
      {isStockFull[0]?.isStockFull === "true" ? (
        <AbandonOrder />
      ) : (
        <div className="max-w-[1440px] mx-auto my-0">
          <div className="block lg:flex ">
            <div className="w-full lg:w-[70%] bg-white p-5 flex flex-col justify-between">
              <div id={keyValue}>
                <p className="text-xl lg:text-3xl font-bold font-Lustria text-black">
                  Select Desired Shape
                </p>
                <p className="mb-6 text-lg lg:text-xl font-Lustria text-black">
                  Step 1
                </p>
                {isLoading ? (
                  <Loading />
                ) : (
                  <div>
                    <div
                      className={`flex flex-wrap gap-[20px] sm:gap-[20px] justify-around ${
                        show ? "" : "[&>*:nth-child(n+7)]:hidden"
                      }  `}
                    >
                      {shapeData.map((showdata, index) => (
                        <DetailsCard
                          key={index}
                          showdata={showdata}
                          name={keyValue}
                          setRedirect={setRedirect}
                          selectedButton={selectedButton}
                          setSelectedButton={setSelectedButton}
                        />
                      ))}
                    </div>
                    {shapeData.length >= 7 ? (
                      <div
                        className={`flex justify-center items-center my-8 down-arrow-div ${
                          show ? "hidden" : ""
                        }`}
                      >
                        <div
                          onClick={() => setShow(true)}
                          className="w-0 h-0
   border-l-[40px] border-l-transparent
   border-t-[40px] border-t-gray-400
   border-r-[40px] border-r-transparent
    "
                        ></div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              <RedirectButtons
                step={step}
                selectedDataFunction={null}
                redirect={redirect}
                editedOrder={null}
                newCustom={null}
              />
            </div>
            <Summary step={step} productPrice="0.00" data={null} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DesiredShape;
