import React, { useContext, useEffect, useState } from "react";
import DetailsCard from "../Reuseable/DetailsCard";
import Summary from "../Summary/Steppers";
import { useNavigate } from "react-router-dom";
import RedirectButtons from "../Reuseable/RedirectButtons";
import { projectContext } from "../../Context/projectContext";
import m2mApiClass from "../../firestoreBackendAPI";
import Loading from "../../Shared/Loading";

const FillingType = ({ step, keyValue }) => {
  const {
    fillingData,
    newCustom,
    setNewCustom,
    setFillingData,
    isLoading,
    setIsLoading,
  } = useContext(projectContext);
  const [redirect, setRedirect] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedButton, setSelectedButton] = useState(newCustom.filling);
  let navigate = useNavigate();


  const fetchData = async () => {
    const allfetchedFilling = await m2mApiClass.getFillingData();
    setFillingData(allfetchedFilling);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();

  }, []);

  useEffect(() => {
    if (!newCustom.filling) {
      setRedirect(false);
    }
    if (newCustom.filling) {
      setRedirect(true);
    }

    window.addEventListener("load", () => {
      navigate("/step-1");
    });
  }, [navigate, newCustom.filling]);

  return (
    <div className="max-w-[1440px] mx-auto my-0">
      <div className="block lg:flex">
        <div className="w-full lg:w-[70%] bg-white p-5 flex flex-col justify-between">
          <div id={keyValue}>
            <p className="text-xl lg:text-3xl font-bold font-Lustria text-black">
              Select Filling Type
            </p>
            <p className="mb-6 text-lg lg:text-xl font-Lustria text-black">
              Step 2
            </p>
            {isLoading ? (
              <Loading />
            ) : (
              <div>
                <div
                  className={`flex flex-wrap gap-[20px] sm:gap-[20px] justify-around ${
                    show ? "" : "[&>*:nth-child(n+7)]:hidden"
                  }  `}
                >
                  {fillingData.map(
                    (showdata, index) =>
                      showdata.shapeType.includes(newCustom.shape) && (
                        <DetailsCard
                          key={index}
                          showdata={showdata}
                          name={keyValue}
                          setRedirect={setRedirect}
                          selectedButton={selectedButton}
                          setSelectedButton={setSelectedButton}
                          setNewCustom={setNewCustom}
                          newCustom={newCustom}
                        />
                      )
                  )}
                </div>
                {fillingData.length >= 7 ? (
                  <div
                    className={`flex justify-center items-center my-8 down-arrow-div ${
                      show ? "hidden" : ""
                    }`}
                  >
                    <div
                      onClick={() => setShow(true)}
                      className="w-0 h-0
  border-l-[40px] border-l-transparent
  border-t-[40px] border-t-gray-400
  border-r-[40px] border-r-transparent
  "
                    ></div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <RedirectButtons
            step={step}
            selectedDataFunction={null}
            redirect={redirect}
            editedOrder={null}
            newCustom={null}
          />
        </div>
        <Summary step={step} productPrice="0.00" data={null} />
      </div>
    </div>
  );
};

export default FillingType;
