import React from "react";
import { Route, Routes } from "react-router-dom";
import DesiredShape from "./Components/Desired Shape/DesiredShape";
import FillingType from "./Components/Filling Type/FillingType";
import RequiredDimensions from "./Components/Required Dimension/RequiredDimensions.jsx";
import FabricWithColour from "./Components/Fabric With Colour/FabricWithColour";
import Overview from "./Components/Overview/Overview";
import Dashboard from "./Components/Dashboard/Dashboard";
import AddShape from "./Components/Dashboard/AddShape";
import AddColor from "./Components/Dashboard/AddColor";
import AddFilling from "./Components/Dashboard/AddFilling";
import Checkout from "./Components/Checkout/Checkout";
import CustomerDetails from "./Components/Customer Details/CustomerDetails.jsx";
import UpdatePrice from "./Components/Dashboard/UpdatePrice";
import AllOrders from "./Components/Dashboard/AllOrders";
import AddFabrics from "./Components/Dashboard/AddFabrics";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThankYou from "./Components/Thank You Page/ThankYou";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/Authentication/Login";
import RequireAuth from "./Components/RequireAuth";
import Summary from "./Components/Dashboard/Summary";
import UpdateCredentials from "./Components/Authentication/UpdateCredentials";
import "react-tooltip/dist/react-tooltip.css";
import PasswordReset from "./Components/Authentication/PasswordReset";
import NotFound from "./Components/NotFound";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={<DesiredShape step={["one"]} keyValue={"Desired-Shape"} />}
        />
        <Route
          path="/step-1"
          element={<DesiredShape step={["one"]} keyValue={"Desired-Shape"} />}
        />
        <Route
          path="/step-2"
          element={
            <FillingType step={["one", "two"]} keyValue={"Filling-Type"} />
          }
        />
        <Route
          path="/step-3"
          element={
            <RequiredDimensions
              step={["one", "two", "three"]}
              keyValue={"Required-Dimensions"}
            />
          }
        />
        <Route
          path="/step-4"
          element={
            <FabricWithColour
              step={["one", "two", "three", "four"]}
              keyValue={"Fabric-Type-&-Colour"}
            />
          }
        />
        <Route path="/overview" element={<Overview keyValue={"Overview"} />} />

        <Route path="/customerDetails" element={<CustomerDetails />} />

        <Route path="/checkout" element={<Checkout />} />

        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route index element={<Summary />} />
          <Route path="shape" element={<AddShape />} />
          <Route path="color" element={<AddColor />} />
          <Route path="fabric" element={<AddFabrics />} />
          <Route path="filling" element={<AddFilling />} />
          <Route path="update-price" element={<UpdatePrice />} />
          <Route path="our-orders" element={<AllOrders />} />
          <Route path="change-credentials" element={<UpdateCredentials />} />
        </Route>
        <Route path="admin" element={<Login />} />
        <Route path="password-reset" element={<PasswordReset />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
