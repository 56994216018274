import React from "react";

const OrderSummary = ({ order }) => {
  return (
    <div className="flex flex-col justify-start items-start bg-gray-50 w-full p-6 md:p-14">
      <div>
        <h1 className="text-2xl font-semibold leading-6 text-gray-800 text-black">
          Order Summary
        </h1>
      </div>
      <div className="mt-7 w-full">
        <div className="flex justify-between w-full items-center my-4 text-black">
          <p className="text-lg leading-4 text-gray-600 font-Lustria">
            Total Items
          </p>
          <p className="text-lg font-semibold leading-4 text-gray-600 font-Lustria">
            {order.reduce((total, obj) => obj.quantity + total, 0)}
          </p>
        </div>
        <div className="flex justify-between w-full items-center text-black">
          <p className="text-lg leading-4 text-gray-600 font-Lustria">
            Charges
          </p>
          <p className="text-lg font-semibold leading-4 text-gray-600 font-Lustria">
            £
            {(order
              .reduce((total, obj) => obj.updatedPrice + total, 0))
              .toFixed(2)}
          </p>
        </div>
        <div className="flex justify-between w-full items-center my-4 text-black">
          <p className="text-lg leading-4 text-gray-600 font-Lustria">VAT</p>
          <p className="text-lg font-semibold leading-4 text-gray-600 font-Lustria">
            £
            {(order.reduce((total, obj) => Number(obj.updatedVAT) + total, 0)).toFixed(2)}
          </p>
        </div>
      </div>
      <div className="flex justify-between w-full items-center mt-32 text-black">
        <p className="text-xl font-semibold leading-4 text-gray-800 font-Lustria">
          Total
        </p>
        <p className="text-lg font-semibold leading-4 text-gray-800 font-Lustria">
          £
          {(
            order.reduce((total, obj) => Number(obj.updatedVAT) + total, 0) +
            order.reduce((total, obj) => obj.updatedPrice + total, 0)
          ).toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default OrderSummary;
