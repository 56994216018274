import React, { useState, useEffect } from "react";
import { auth } from "../../firestoreConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import Loading from "../../Shared/Loading";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // destructuring the hook......
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // handle password reset
  const handlePasswordReset = (e) => {
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          toast("Email Sent! Check Your inbox.");
        })
        .catch((error) => {
          toast.error("Please enter your correct email!");
        });
    } else {
      toast.error("Please enter your email first!");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(email, password);
    event.target.reset();
  };

  const from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, from, navigate]);

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div className="flex justify-center items-center h-screen bg-slate-50">
      <form
        onSubmit={handleSubmit}
        className="bg-white py-12 px-16 rounded shadow-md"
      >
        <p className="text-[14px] mb-4 font-bold">
          Sign In to Bespoke Admin Dashboard!
        </p>

        {error && (
          <p className=" mb-2 text-red-500 font-bold text-[12px]">
            Check your email and password!
          </p>
        )}
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 font-bold mb-2"
          ></label>
          <input
            type="email"
            id="email"
            autoComplete="useremail"
            className="appearance-none border-b-1 border-t-0 border-x-0 w-[220px] py-2 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Email"
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block text-gray-700 font-bold mb-2"
          ></label>
          <input
            type="password"
            id="password"
            autoComplete="current-password"
            className="appearance-none border-b-1 border-t-0 border-x-0 w-[220px] py-2 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Password"
            onChange={handlePasswordChange}
            required
          />
        </div>
        <p
          onClick={handlePasswordReset}
          className="text-blue-500 text-[14px] cursor-pointer font-bold pb-4"
        >
          Forgot Password?
        </p>

        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Sign In
        </button>
      </form>
    </div>
  );
};

export default Login;
