import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { projectContext } from "../../Context/projectContext";

const ConfirmationModal = ({ label, order, confirmation }) => {
  const { setFinalData, setNewCustom } = useContext(projectContext);
  const navigate = useNavigate();

  return (
    <div>
      <input type="checkbox" id={label} className="modal-toggle" />
      <div className="modal modal-middle">
        <div className="modal-box !bg-white">
          <p className="font-bold font-Lustria text-lg text-red-500">
            Are you sure you want to {label.split("-")[0]} {order.title} ?
          </p>
          <div className="modal-action text-black">
            <label
              htmlFor={label}
              onClick={
                label.split("-")[0] === "delete"
                  ? () => confirmation(order.order, setFinalData)
                  : () => confirmation(order.order, setNewCustom, navigate)
              }
              className="btn btn-xs !bg-green-400 text-white pt-[2px]"
            >
              Yes
            </label>
            <label
              htmlFor={label}
              className="btn btn-xs !bg-red-400 text-white pt-[2px]"
            >
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
