import React from 'react';

const NotFound = () => {
    return (
        <div className='flex items-center justify-center h-screen m-4'>
            <p className='text-white text-2xl bg-green-500 p-16 rounded'>Sorry! This page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;