import React, { useEffect, useState } from "react";
import AddShapeModal from "./Modals/AddShapeModal";
import m2mApiClass from "../../firestoreBackendAPI";
import UpdateShapeModal from "./Modals/UpdateShapeModal";
import ButtonLoading from "../../Shared/ButtonLoading";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineEditNote } from "react-icons/md";
import ConfirmationDelete from "./Modals/ConfirmationDelete";
import { Tooltip } from "react-tooltip";

const AddShape = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fillingNotify, setFillingNotify] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    imageWithoutGuide: "",
    imageWithGuide: "",
    description: "",
    id: "",
    _id: "",
  });
  const [addFormData, setAddFormData] = useState({
    title: "",
    imageWithoutGuide: "",
    imageWithGuide: "",
    description: "",
    id: "",
    _id: "",
    isCircle: "false",
  });
  const [shapes, setShapes] = useState([]);
  const fetchShapes = async () => {
    const allfetchedShapes = await m2mApiClass.getShapeData();

    setShapes(allfetchedShapes);
    setIsLoading(false);
  };

  const allShapeLabel = [];
  if (shapes.length > 0) {
    shapes.forEach((shape) => allShapeLabel.push(shape.title));
  }
  const handleUpdateData = (currentData) => {
    let updatedData = {
      title: currentData?.title,
      imageWithoutGuide: currentData?.imageWithoutGuide,
      imageWithGuide: currentData?.imageWithGuide,
      description: currentData?.description,
      id: currentData?.id,
      _id: currentData?._id,
      isCircle: currentData?.isCircle,
    };
    setFormData(updatedData);
  };


  useEffect(() => {
    fetchShapes();
  }, []);

  return (
    <div className="container px-4">
      <div className="container flex flex-col items-center justify-between py-2 my-4 sm:flex-row">
        <h2 className="font-bold text-xl sm:text-3xl mb-2 sm:mb-0">
          Product Shape
        </h2>
       {fillingNotify && <p className="relative bg-yellow-200 rounded font-bold py-4 px-4 lg:px-20 m-2">Warning! Don't Forget to Add Filling for the new Shape!
         <span className="absolute top-0 right-2 text-[18px] cursor-pointer" onClick={()=>setFillingNotify(false)}>×</span> </p>} 
        <label
          className="p-3 px-6 pt-2 text-white bg-error rounded-full baseline font-bold hover:bg-red-500 cursor-pointer"
          htmlFor="my-modal-6"
        >
          Add Shape
        </label>
      </div>

      {isLoading ? (
        <ButtonLoading />
      ) : (
        <div className="overflow-x-auto">
          <table className="table w-full">
            {/* head */}
            <thead>
              <tr>
                <th>Name</th>
                <th>Image 1</th>
                <th>Image 2</th>
                <th>Description</th>
                <th>Edit / Delete</th>
              </tr>
            </thead>
   
            <tbody>
              {shapes?.map((shape) => (
                <tr key={shape._id} id={"__".concat(shape._id)}>
                  {shape.title.length > 24 ? (
                    <td
                      id={"__1".concat(shape._id)}
                      data-tooltip-id="shape-tooltip"
                      data-tooltip-content={shape.title}
                      data-tooltip-place="top"
                      className="cursor-pointer"
                    >
                      {shape.title.slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__1".concat(shape._id)}`}
                        id="shape-tooltip"
                        effect="solid"
                        clickable
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__1".concat(shape._id)}> {shape.title} </td>
                  )}
                  {shape.imageWithoutGuide.length > 24 ? (
                    <td
                      id={"__2".concat(shape._id)}
                      data-tooltip-id="shape-tooltip"
                      data-tooltip-content={shape.imageWithoutGuide}
                      data-tooltip-place="top"
                      className="cursor-pointer"
                    >
                      {shape.imageWithoutGuide.slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__2".concat(shape._id)}`}
                        id="shape-tooltip"
                        effect="solid"
                        clickable
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__2".concat(shape._id)}>
                      {shape.imageWithoutGuide}{" "}
                    </td>
                  )}

                  {shape.imageWithGuide.length > 24 ? (
                    <td
                      id={"__3".concat(shape._id)}
                      data-tooltip-id="shape-tooltip"
                      data-tooltip-content={shape.imageWithGuide}
                      data-tooltip-place="top"
                      className="cursor-pointer"
                    >
                      {shape.imageWithGuide.slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__3".concat(shape._id)}`}
                        id="shape-tooltip"
                        effect="solid"
                        clickable
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__3".concat(shape._id)}>
                      {shape.imageWithGuide}{" "}
                    </td>
                  )}

                  {shape.description.length > 24 ? (
                    <td
                      id={"__4".concat(shape._id)}
                      data-tooltip-id="shape-tooltip"
                      data-tooltip-content={shape.description}
                      data-tooltip-place="top"
                      data-tool-type="description"
                      className="cursor-pointer"
                    >
                      {shape.description.slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__4".concat(shape._id)}`}
                        id="shape-tooltip"
                        data-tool-type="shape-description"
                        effect="solid"
                        clickable
                        className="custom-tooltip-width z-[20] !max-w-[350px] whitespace-normal text-justify"
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__4".concat(shape._id)}>{shape.description} </td>
                  )}

                  <td className="flex items-center">
                    <label htmlFor="update-shape-modal-elx">
                      <MdOutlineEditNote
                        className="object-cover w-8 h-8 cursor-pointer"
                        onClick={() => handleUpdateData(shape)}
                      ></MdOutlineEditNote>
                    </label>

                    <label htmlFor="confirmation-delete">
                      <RiDeleteBin6Line
                        className="object-cover ml-2 w-6 h-6 cursor-pointer"
                        onClick={() => handleUpdateData(shape)}
                      ></RiDeleteBin6Line>
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <AddShapeModal
        formData={addFormData}
        setFormData={setAddFormData}
        fetchShapes={fetchShapes}
        allShapeLabel={allShapeLabel}
        setFillingNotify={setFillingNotify}
      ></AddShapeModal>

      <ConfirmationDelete
        currentData={formData}
        allFetchedData={fetchShapes}
        apiMethod={m2mApiClass.deleteData}
      ></ConfirmationDelete>

      <UpdateShapeModal
        formData={formData}
        setFormData={setFormData}
        fetchShapes={fetchShapes}
        allShapeLabel={allShapeLabel}
      ></UpdateShapeModal>

    </div>
  );
};

export default AddShape;
