import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import ConfirmationStockOff from "../Components/Dashboard/Modals/ConfirmationStockOff";

const AdminCheckbox = ({ isChecked, setIsChecked, _id }) => {
  const handleChangeOption =(e)=>{
    setIsChecked(e.target.value);
  }
  return (
    <>
      <div className="w-[90%] mx-auto mt-8 border-0 rounded bg-yellow-100 p-8 flex flex-col sm:flex-row sm:items-center justify-between">
        <div className="pr-6 text-justify">
          <p className="text-xl font-semibold">
            Do you want to stop taking orders?
          </p>
          <p className="">
            Warning! Clicking Yes will stop taking orders. Only click yes if you
            are over loaded. By Default its No.
          </p>
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6 sm:mt-0">
          <label className="inline-flex items-center">
            <input
              checked={isChecked === "true" && true}
              type="radio"
              className="form-radio text-indigo-600"
              name="radio-group"
              value="true"
              onChange={handleChangeOption}
            />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={isChecked === "false" && true}
              className="form-radio text-indigo-600"
              name="radio-group"
              value="false"
              onChange={handleChangeOption}
            />
            <span className="ml-2">No</span>
          </label>
          <label
            htmlFor="confirmation-stock-off"
            className="bg-green-500 p-2 flex gap-2 justify-center items-center rounded hover:scale-[1.1] cursor-pointer"
          >
            <span>
              <BsCheckCircleFill className="w-4 h-4 text-white"></BsCheckCircleFill>
            </span>
            <span className="text-white">Save</span>
          </label>
        </div>
      </div>
      <ConfirmationStockOff isChecked={isChecked} _id={_id} />
    </>
  );
};

export default AdminCheckbox;
