import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth,EmailAuthProvider,reauthenticateWithCredential  } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_M2M_apiKey,
    authDomain: process.env.REACT_APP_M2M_authDomain,
    projectId: process.env.REACT_APP_M2M_projectId,
    storageBucket: process.env.REACT_APP_M2M_storageBucket,
    messagingSenderId: process.env.REACT_APP_M2M_messagingSenderId, 
    appId: process.env.REACT_APP_M2M_appId
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);


export {db,auth,EmailAuthProvider,reauthenticateWithCredential};

