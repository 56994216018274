import React, { useContext } from "react";
import { Link } from "react-router-dom";
import OrderDesign from "../Reuseable/OrderDesign";
import {
  plusQuantityChange,
  minusQuantityChange,
} from "../../Helpers/quantityFunctions";
import { projectContext } from "../../Context/projectContext";

const TotalOverview = () => {
  const { finalData } = useContext(projectContext);

  return (
    <div className="totalOverview p-5 px-[40px] bg-[#A3C398] lg:flex lg:w-[35%] flex-col w-full xl:w-[25%] justify-between">
      <div id="basketSection">
        <p className="text-normal text-2xl mb-6 text-center lg:text-left font-Lustria text-black">
          Basket
        </p>
        <div className="flex flex-col cushionWithDetails">
          {finalData.map((a, index) => (
            <OrderDesign
              key={index}
              data={a}
              minusQuantityChange={minusQuantityChange}
              plusQuantityChange={plusQuantityChange}
            />
          ))}
        </div>
      </div>
      <div id="basketTotal">
        <div className="text-right font-bold text-xl mb-[40px] text-black">
          <p className="text-2xl font-Lustria">Total</p>
          <p className="text-4xl font-Lustria">
            £
            {finalData
              .reduce((total, obj) => obj.updatedPrice + total, 0)
              .toFixed(2)}
          </p>
        </div>
        <div className="flex justify-center ">
          <Link
            to="/customerDetails"
            className="w-full max-w-[250px] text-center py-5 bg-white border-[6px] border-[#DAE4D9] text-black uppercase  rounded-3xl text-[28px]"
            onClick={() => window.scrollTo(0, 0)}
          >
            Pay Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TotalOverview;
