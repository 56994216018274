import React from "react";
import CustomerDetailsCSV from "./CustomerDetailsCSV";

const CustomerDetailsDash = ({ item }) => {
  // need to apply for csv
  const unitPriceCalculator = () => {
    const updatedItem = {
      ...item,
      payment: item.payment ? "Paid" : "Unpaid",
      totalPrice: (Number(item.totalPrice) + Number(item.totalVAT)).toFixed(2),
      vat: Number(item.totalVAT),
      subTotalPrice: Number(item.totalPrice),
      orderDate: new Date(item.orderDate).toLocaleString("en-GB", {
        timeZone: "Europe/London",
      }),
    };

    updatedItem.orderDetails.forEach((order) => {
      order.price = (
        (order.updatedPrice + Number(order.updatedVAT)) /
        order.quantity
      ).toFixed(2);
      order.updatedPriceWithVAT = order.updatedPrice + Number(order.updatedVAT);
    });

    return updatedItem;
  };

  let detailedItem = [unitPriceCalculator()];

  return (
    <React.Fragment>
      <div className="bg-gray-200 p-4 absolute left-0 w-[100%] z-50">
        <div className="flex justify-between">
          <h2 className="text-lg font-bold mb-2">Customer Details</h2>
          <div className="flex gap-5">
            <p
              className={`${
                item.payment
                  ? "bg-green-500 text-white"
                  : "bg-red-500 text-white"
              } py-2 px-6 rounded-full text-center`}
            >
              {item.payment ? "Paid" : "Unpaid"}
            </p>

            <button className="bg-white hover:bg-gray-100 font-bold py-2 px-4 rounded border-0">
              <CustomerDetailsCSV detailedItem={detailedItem} />
            </button>
          </div>
        </div>

        <div className="flex flex-wrap gap-4">
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">Name: </span> {item.firstName}{" "}
            {item.lastName}
          </p>
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">Email: </span> {item.email}{" "}
          </p>
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">Address: </span> {item.address}
          </p>
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">City: </span> {item.city}
          </p>
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">Region: </span> {item.region}
          </p>
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">Zip Code: </span> {item.zipCode}
          </p>
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">Country: </span>
            {item.country}
          </p>
          <p className="w-full sm:w-1/2 xl:w-1/5">
            <span className="font-bold">Phone: </span> {item.phoneNumber}
          </p>
        </div>

        <div>
          <h2 className="text-lg font-bold mt-8 mb-2">Order Details</h2>

          <div className="">
            {item.orderDetails.map((order, index) => (
              <div className="my-5 bg-white p-4 rounded" key={index}>
                <p className="w-full sm:w-1/2 lg:w-1/5 font-bold text-xl">
                  {order.title}
                </p>

                <p className="w-full sm:w-1/2 xl:w-1/5">
                  <span className="font-bold">Shape:</span>{" "}
                  {order.shape.includes("-")
                    ? order.shape.charAt(0).toUpperCase() +
                      order.shape.slice(1).split("-").join(" ")
                    : order.shape.charAt(0).toUpperCase() +
                      order.shape.slice(1)}
                </p>

                <p className="w-full sm:w-1/2 xl:w-1/5">
                  <span className="font-bold">Filling:</span>{" "}
                  {order.filling.includes("-")
                    ? order.filling.charAt(0).toUpperCase() +
                      order.filling.slice(1).split("-").join(" ")
                    : order.filling.charAt(0).toUpperCase() +
                      order.filling.slice(1)}
                </p>

                <p className="w-full sm:w-1/2 xl:w-1/5">
                  <span className="font-bold">Dimensions:</span>
                </p>

                {/* dimensions */}

                <div className="flex flex-wrap">
                  <p className="mr-4">
                    <span className="font-bold">Length: </span>
                    {order.dimensions.length}cm
                  </p>

                  <p className="mr-4">
                    <span className="font-bold">Width: </span>
                    {order.dimensions.width}cm
                  </p>

                  <p className="mr-4">
                    <span className="font-bold">Thickness: </span>
                    {order.dimensions.thickness}cm
                  </p>

                  <p className="mr-4">
                    <span className="font-bold">Has Ties: </span>{" "}
                    {order.dimensions.hasTies ? "Yes" : "No"}
                  </p>
                </div>

                {/* end dimensions */}

                <p className="w-full sm:w-1/2 xl:w-1/5">
                  <span className="font-bold">Color: </span>{" "}
                  {order.color.charAt(0).toUpperCase() + order.color.slice(1)}
                </p>

                <p className="w-full sm:w-1/2 xl:w-1/5">
                  <span className="font-bold">Fabric: </span>{" "}
                  {order.fabric.charAt(0).toUpperCase() + order.fabric.slice(1)}
                </p>

                <p className="w-full sm:w-1/2">
                  <span className="font-bold">Unit Price: </span> {order.price}£
                  (Inc. VAT)
                </p>

                <p className="w-full sm:w-1/2">
                  <span className="font-bold">Total Quantity: </span>{" "}
                  {order.quantity}
                </p>

                <p className="w-full sm:w-1/2">
                  <span className="font-bold">Sub-total Price: </span>{" "}
                  {(order.updatedPrice + Number(order.updatedVAT)).toFixed(2)}£
                  (Inc. VAT)
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="my-5 mb-10">
          <h2 className="text-xl font-bold mb-2 mt-8">Payment Details</h2>
          <table className="w-1/4 text-lg mb-8">
            <tbody>
              <tr className="font-bold">
                <td>Sub-total Price:</td>
                <td className="text-right"> {item.totalPrice}£</td>
              </tr>
              <tr className="font-bold border-b-2 border-black">
                <td>VAT:</td>
                <td className="text-right">
                  {Number(item.totalVAT).toFixed(2)}£
                </td>
              </tr>
              <tr className="font-bold">
                <td>Total Price:</td>
                <td className="text-right">
                  {(Number(item.totalPrice) + Number(item.totalVAT)).toFixed(2)}
                  £
                </td>
              </tr>
            </tbody>
          </table>

          <p className="mt-4 font-bold">
            Status:
            <span
              className={`${
                item.payment
                  ? "bg-green-500 text-white"
                  : "bg-red-500 text-white"
              } py-3 px-6 rounded-full ml-1 text-center`}
            >
              {item.payment ? "Paid" : "Unpaid"}
            </span>
          </p>

          <p className="w-full font-bold mt-6">
            Invoice ID:
            <span className=""> {item?.linnworksInvoiceID}</span>
          </p>

          <p className="w-full font-bold mt-3 mb-2">
            TrxID:
            <span className="">
              {" "}
              {item?.paymentDetails?.purchase_units
                ? item?.paymentDetails?.purchase_units[0]?.payments?.captures[0]
                    ?.id
                : ""}
            </span>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerDetailsDash;
