import React,{useState} from "react";
import { toast } from "react-toastify";

const ConfirmationDelete = ({
  currentData,
  allFetchedData,
  apiMethod,
  index,
  optionalArrayofObject,
  optionalArrayofObjectFunc,
}) => {
  const [isActionLoading, setIsActionLoading] = useState(false);
  const handleDelete = async () => {
    if (currentData._id) {
      try {
        setIsActionLoading(true);
        await apiMethod(currentData._id);
        toast("Data Deleted Successfully!");
        await allFetchedData();

      } catch (error) {
        toast.error("Error While Deleting Data!");
      } finally {
        setIsActionLoading(false);

      }
    } else {
      optionalArrayofObjectFunc(
        optionalArrayofObject
          .slice(0, index)
          .concat(optionalArrayofObject.slice(index + 1))
      );
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        id="confirmation-delete"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Do you want to delete this?</h3>

          <div className="modal-action">
            <label htmlFor="confirmation-delete">
              <p className="btn" onClick={()=>handleDelete()}>
              {isActionLoading ? "Updating..." : "Yes"}

              </p>
            </label>
            <label htmlFor="confirmation-delete" className="btn">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDelete;
