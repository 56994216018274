import React, { useContext } from "react";
import { projectContext } from "../../Context/projectContext";

const Summary = ({ step, productPrice, data }) => {
  const { newCustom } = useContext(projectContext);
  return (
    <div className="w-full lg:w-[30%] bg-[#A3C398] flex flex-col sticky top-0 z-10">
      <div className="breadcrumbs p-3 lg:pt-[63px] lg:pl-[52px] lg:pr-[50px]">
        <p className="font-Lustria font-regular text-[24px] leading-[31px] text-left mb-[20px] lg:mb-[70px] text-black">
          Progress Tracker
        </p>
        <div className="mb-2 lg:mb-[70px] flex justify-between items-center lg:flex-col lg:justify-center lg:items-start gap-y-[50px] relative">
          <div
            id="shape-type"
            className="flex flex-col lg:flex-row justify-start gap-2 lg:gap-6 w-[100px] md:w-[170px] lg:w-full relative items-center h-[150px] xsm:h-[90px] z-[10] text-black"
          >
            <input
              type="checkbox"
              checked={step.length > 1 && true}
              name="breadcrumbs-checkbox"
              id="checkbox-one"
              className="cursor-pointer w-7 h-7 border-3 rounded-full checked:bg-green-500 hover:bg-white-500"
              disabled
            />
            <div className="text-center lg:text-left ">
              <p className="font-Lustria text-[12px] font-bold lg:font-normal lg:text-2xl lg:leading-[31px]">
                Shape Type
              </p>
              <p className="font-Lustria text-[12px] lg:text-[15px] leading-5 capitalize">
                {newCustom.shape
                  ? newCustom.shape.includes("-")
                    ? newCustom.shape.split("-").join(" ")
                    : newCustom.shape
                  : "Select Shape Type"}
              </p>
            </div>
          </div>

          <div
            id="filling-type"
            className="flex flex-col lg:flex-row justify-start gap-2 lg:gap-6 w-[100px] md:w-[170px] lg:w-full  relative items-center h-[150px] xsm:h-[90px] z-[10] text-black"
          >
            <input
              type="checkbox"
              checked={step.length > 2 && true}
              name="breadcrumbs-checkbox"
              id="checkbox-two"
              className="cursor-pointer w-7 h-7 border-3 rounded-full checked:bg-green-500 hover:bg-white-500"
              disabled
            />
            <div className="text-center lg:text-left">
              <p className="font-Lustria text-[12px] font-bold lg:font-normal lg:text-2xl lg:leading-[31px]">
                Filling Type
              </p>
              <p className="font-Lustria text-[12px] lg:text-[15px] leading-5 capitalize">
                {newCustom.filling
                  ? newCustom.filling.includes("-")
                    ? newCustom.filling.split("-").join(" ")
                    : newCustom.filling
                  : "Select Filling Type"}
              </p>
            </div>
          </div>

          <div
            id="dimensionsDetails"
            className="flex flex-col lg:flex-row justify-start gap-2 lg:gap-6 w-[100px] md:w-[170px] lg:w-full relative items-center h-[150px] xsm:h-[90px] z-[10] text-black"
          >
            <input
              type="checkbox"
              checked={step.length > 3 && true}
              name="breadcrumbs-checkbox"
              id="checkbox-three"
              className="cursor-pointer w-7 h-7 border-3 rounded-full checked:bg-green-500 hover:bg-white-500"
              disabled
            />
            <div className="text-center lg:text-left">
              <p className="font-Lustria text-[12px] font-bold lg:font-normal lg:text-2xl lg:leading-[31px] ">
                Dimensions
              </p>
              <p className="font-Lustria text-[12px] lg:text-[15px] leading-5">
                {newCustom.dimensions.width
                  ? newCustom.dimensions.width +
                      " W x " +
                      (data?.isCircle ===
                    "true"
                    ? newCustom.dimensions.width
                    : newCustom.dimensions.length) +
                      " L x " +
                      newCustom.dimensions.thickness +
                      " H cm"
                  : "Select Required Dimensions"}
              </p>
            </div>
          </div>

          <div
            id="fabricWithColor"
            className="flex flex-col lg:flex-row justify-start gap-2 lg:gap-6 w-[100px] md:w-[170px] lg:w-full relative items-center h-[150px] xsm:h-[90px] z-[10] text-black"
          >
            <input
              type="checkbox"
              checked={step.length > 4 && true}
              name="breadcrumbs-checkbox"
              id="checkbox-four"
              className="cursor-pointer w-7 h-7 border-3 rounded-full checked:bg-green-500 hover:bg-white-500"
              disabled
            />
            <div className="text-center lg:text-left">
              <p className="font-Lustria text-[12px] font-bold lg:font-normal lg:text-2xl lg:leading-[31px]">
                Fabric type & Colour
              </p>
              <p className="font-Lustria text-[12px] lg:text-[15px] leading-5 capitalize">
                {newCustom.fabric
                  ? newCustom.fabric +
                    "-" +
                    (newCustom.color && newCustom.color)
                  : "Select Fabric & Colour"}
              </p>
            </div>
          </div>

          <div className="border-[1px] border-dashed absolute left-[35px] xsm:left-[50px] md:left-[85px] lg:left-[13px] w-[calc(100%-70px)] xsm:w-[calc(100%-100px)] md:w-[calc(100%-170px)] lg:w-0 top-[13px] lg:top-[50px] h-[calc(100%-150px)] xsm:h-[calc(100%-90px)]"></div>
        </div>
      </div>
      <div
        id="productTotal"
        className="text-2xl text-2xl pr-3 lg:pr-6 pb-3 flex gap-4 justify-end items-center lg:flex-col lg:items-end text-black"
      >
        <p className="font-Lustria lg:text-2xl text-lg">Total </p>
        <p className="font-Lustria lg:text-4xl text-xl">£{productPrice}</p>
      </div>
    </div>
  );
};

export default Summary;
