import React from "react";
const ShapeFormCircleConfirmation = ({handleChange,formData}) => {

  return (
    <>
      <div className="w-[100%] mx-auto my-4 border-0 rounded p-2 flex flex-col">
        <div className="mb-2">
          <p className="font-bold">Are length and width same?</p>
        </div>

        <div className="flex space-x-4 mt-6 sm:mt-0">
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={ formData.isCircle === "true" && true}
              className="form-radio text-indigo-600"
              name="isCircle"
              value="true"
              onChange={handleChange}
            />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={ formData.isCircle === "false" && true}
              className="form-radio text-indigo-600"
              name="isCircle"
              value="false"
              onChange={handleChange}
            />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>
    </>
  );
};

export default ShapeFormCircleConfirmation;
