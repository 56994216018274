import React, { useContext } from "react";
import { projectContext } from "../../Context/projectContext";
import { Tooltip } from "react-tooltip";
const DetailsCard = ({
  showdata,
  name,
  setRedirect,
  selectedButton,
  setSelectedButton,
}) => {
  const { setNewCustom } = useContext(projectContext);

  function handleRadioChange(event) {
    const clickedButton = event.target.value;
    setSelectedButton(clickedButton);
    setRedirect(true);
    if (window.location.href.includes("step-2")) {
      setNewCustom((prevState) => ({
        ...prevState,
        filling: clickedButton,
      }));
    } else if (
      window.location.href.includes("step-1") ||
      window.location.href.includes("/")
    ) {
      setNewCustom((prevState) => ({
        ...prevState,
        shape: clickedButton,
      }));
    }
  }

  return (
    <label
      htmlFor={showdata.id}
      className={`${name.toLowerCase()} cursor-pointer inline-block bg-[#EEEEEE] p-4 border-2 rounded-3xl relative xsm:max-w-[75%] sm:max-w-[45%] md:max-w-[30%] ${
        selectedButton === showdata.id
          ? "border-[#B0D5EF] border-4"
          : "border-black"
      }`}
    >
      <input
        className="cursor-pointer w-[100%] h-[100%] opacity-0 absolute top-0 left-0"
        type="radio"
        id={showdata.id}
        name={name.toLowerCase()}
        value={showdata.id}
        checked={selectedButton === showdata.id}
        onChange={(e) => handleRadioChange(e)}
      />
      <img
        className={`m-auto ${
          name === "Filling-Type" && "rounded-full max-w-[70%]"
        }`}
        src={showdata.imageWithoutGuide}
        alt={showdata.id + "image Without Guide"}
      />
      <p className="text-[15px] leading-[19.23px] text-center mt-[20px] font-Lustria text-black">
        {showdata.title}
      </p>
      {showdata.description && showdata.description.length > 20 ? (
        <>
          <p
            id={"__".concat(showdata.id)}
            data-tooltip-id="showdata-tooltip"
            data-tooltip-content={showdata.description}
            data-tooltip-place="top"
            className="relative my-5 text-[13px] text-center font-Lustria text-black cursor-pointer after:content-[''] after:bg-transparent after:absolute after:inset-0 break-words"
          >
            {showdata.description.slice(0, 30)}...
          </p>
        </>
      ) : (
        <p
          className="w-full text-[13px] leading-[16.67px] text-center mt-[25px] font-Lustria text-black cursor-pointer"
          id={"__".concat(showdata.id)}
        >
          {showdata.description}
        </p>
      )}
      <Tooltip
        anchorSelect={`#${"__".concat(showdata.id)}`}
        id="showdata-tooltip"
        effect="solid"
        clickable
        className="z-[20] !max-w-[320px] whitespace-normal text-justify pointer-events-none"
      />
    </label>
  );
};

export default DetailsCard;
