import React, { useContext, useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { deleteOrder, editOrder } from "../../Helpers/orderFunctions";
import { projectContext } from "../../Context/projectContext";
import {
  plusQuantityChange,
  minusQuantityChange,
} from "../../Helpers/quantityFunctions";
import ButtonLoading from "../../Shared/ButtonLoading";

const CustomCushionDetails = ({ data, selectedOrder, setSelectedOrder }) => {
  const { calculationPriceData, finalData, setFinalData, shapeData } =
    useContext(projectContext);

  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [isEditConfirmed, setIsEditConfirmed] = useState(false);


  return (
    <div className="bg-white">
      <div className="bg-[#EEEEEE] border-2 border-[#B0B0B0] rounded-[50px] px-5 py-5 max-w-[330px] relative">
        <label
          onClick={() => {
            setIsDeleteConfirmed(true);
            setSelectedOrder(data);
          }}
          htmlFor="delete-confirm-modal"
          className="absolute top-[-3%] left-[-5%] bg-white rounded-full cursor-pointer"
        >
          <img
            src="https://i.ibb.co/xmVC6rr/Delete-Order-Button.png"
            alt="CrossButton"
          />
        </label>

        <div className="flex items-center justify-between">
          <p className="text-[15px] sm:text-xl border-gray-500 my-3 mr-4 font-Lustria text-black">
            {data.title}
          </p>
          <div className="flex items-center bg-white max-w-[100%] max-h-[27px] rounded-lg text-black">
            <p
              className="px-2 text-center cursor-pointer border-r-2 border-black text-lg"
              onClick={() =>
                minusQuantityChange(
                  data.order,
                  finalData,
                  setFinalData,
                  calculationPriceData
                )
              }
            >
              -
            </p>
            <p className="mx-2 font-Lustria text-black">
              {data.quantity < 10 ? "0" : ""}
              {data.quantity}
            </p>
            <p
              className="px-2 text-center cursor-pointer border-l-2 border-black text-lg text-black"
              onClick={() =>
                plusQuantityChange(
                  data.order,
                  finalData,
                  setFinalData,
                  calculationPriceData
                )
              }
            >
              +
            </p>
          </div>
        </div>

        <div className="m-auto flex justify-center items-center">
          {shapeData.find(
                  (shape) =>
                    shape.title.toLowerCase() ===
                    (data.shape.includes("-")
                      ? data.shape.split("-").join(" ")
                      : data.shape)
                ) ? <img className="w-1/2 h-full"
              src={
                shapeData.find(
                  (shape) =>
                    shape.title.toLowerCase() ===
                    (data.shape.includes("-")
                      ? data.shape.split("-").join(" ")
                      : data.shape)
                )?.imageWithoutGuide
              }
              alt="imageImage"
            /> : <ButtonLoading/>}
            
          </div>

        <div className="relative">
          <div className="flex gap-6 relative items-center my-2 h-[60px] z-10 text-black">
            <input
              type="checkbox"
              checked
              name="breadcrumbs-checkbox"
              id="checkbox-one"
              className="cursor-pointer w-5 h-5 border-3 rounded-full checked:bg-green-500 hover:bg-green-500"
              disabled
            />
            <div>
              <p className="font-bold max-w-[100%] font-Lustria">Shape Type</p>
              <p className="capitalize">
                {data.shape.includes("-")
                  ? data.shape.split("-").join(" ")
                  : data.shape}
              </p>
            </div>
          </div>

          <div className="flex gap-6 relative items-center my-2 h-[60px] z-10 text-black">
            <input
              type="checkbox"
              checked
              name="breadcrumbs-checkbox"
              id="checkbox-one"
              className="cursor-pointer w-5 h-5 border-3 rounded-full checked:bg-green-500 hover:bg-green-500"
              disabled
            />
            <div>
              <p className="font-bold max-w-[100%] font-Lustria">
                Filling Type
              </p>
              <p className="capitalize font-Lustria">
                {data.filling.includes("-")
                  ? data.filling.split("-").join(" ")
                  : data.filling}
              </p>
            </div>
          </div>

          <div className="flex gap-6 relative items-center my-2 h-[60px] z-10 text-black">
            <input
              type="checkbox"
              checked
              name="breadcrumbs-checkbox"
              id="checkbox-one"
              className="cursor-pointer w-5 h-5 border-3 rounded-full checked:bg-green-500 hover:bg-green-500"
              disabled
            />
            <div>
              <p className="font-bold max-w-[100%] font-Lustria">Dimensions</p>
              <p className="font-Lustria">
                {data.dimensions.width} W x {data.dimensions.length} L x {data.dimensions.thickness} H
                cm
              </p>
            </div>
          </div>

          <div className="flex gap-6 relative items-center my-2 h-[60px] z-10 text-black">
            <input
              type="checkbox"
              checked
              name="breadcrumbs-checkbox"
              id="checkbox-one"
              className="cursor-pointer w-5 h-5 border-3 rounded-full checked:bg-green-500 hover:bg-green-500"
              disabled
            />
            <div>
              <p className="font-bold max-w-[100%] font-Lustria">
                Fabric & Color Type
              </p>
              <p className="capitalize font-Lustria">
                {data.fabric}-{data.color.split("-").join(" ")}
              </p>
            </div>
          </div>
          <div className="border-[1px] border-solid border-white absolute top-[30px] left-[10px] h-[calc(100%-60px)]"></div>
        </div>

        <label
          onClick={() => {
            setIsEditConfirmed(true);
            setSelectedOrder(data);
          }}
          htmlFor="edit-confirm-modal"
          className="font-bold text-2xl bg-[#A2C397] text-sm px-[49px] py-[2px] rounded-xl table mx-auto my-4 cursor-pointer text-black"
        >
          Edit
        </label>
      </div>
      {isDeleteConfirmed && (
        <ConfirmationModal
          label={"delete-confirm-modal"}
          order={selectedOrder}
          confirmation={deleteOrder}
        />
      )}
      {isEditConfirmed && (
        <ConfirmationModal
          label={"edit-confirm-modal"}
          order={selectedOrder}
          confirmation={editOrder}
        />
      )}
    </div>
  );
};

export default CustomCushionDetails;
