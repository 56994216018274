import React from 'react';
import {toast } from 'react-toastify';
import m2mApiClass from "../../../firestoreBackendAPI";

const ConfirmationStockOff = ({isChecked, _id}) => {

    const handleChange =()=>{
        const finalData = {
          isStockFull: isChecked
        }
        
    try {
      m2mApiClass.updateStockFull(_id, finalData);
      toast("Changes Updated Successfully!");
    } catch (error) {
      toast.error("Error While Updating Data!");
    } finally {

    }


    }
    return (
        <div>
        <input
          type="checkbox"
          id="confirmation-stock-off"
          className="modal-toggle"
        />
        <div className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Do you want to change it??</h3>
  
            <div className="modal-action">
              <label htmlFor="confirmation-stock-off">
                <p className="btn" onClick={handleChange}>
                  Yes
                </p>
              </label>
              <label htmlFor="confirmation-stock-off" className="btn">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ConfirmationStockOff;