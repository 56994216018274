import React, { useState, useEffect } from "react";
import {
  useAuthState,
  useUpdateEmail,
  useUpdatePassword,
} from "react-firebase-hooks/auth";
import {
  auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "../../firestoreConfig";
import { toast } from "react-toastify";
import AdminCheckbox from "../../Shared/AdminCheckbox";
import ButtonLoading from "../../Shared/ButtonLoading";
import m2mApiClass from "../../firestoreBackendAPI";

function UpdateCredentials() {
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword0, setCurrentPassword0] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPasswordDisabled, setIsPasswordDisabled] = useState(true);
  const [updateEmail, updating, error] = useUpdateEmail(auth);
  const [updatePassword, updatingP, errorP] = useUpdatePassword(auth);
  const [isChecked, setIsChecked] = useState("false");
  const [isLoading, setIsLoading] = useState(true);
  const [_id, set_id] = useState("");
  const [user] = useAuthState(auth);

  const fetchStock = async () => {
    const allfetchedStock = await m2mApiClass.getStockData();
    let { isStockFull, _id } = allfetchedStock[0];
    setIsChecked(isStockFull);
    setIsLoading(false);
    set_id(_id);
  };
  const currentUser = user.auth.currentUser;
  const credential0 = EmailAuthProvider.credential(
    currentUser.email,
    currentPassword0
  );
  const credential = EmailAuthProvider.credential(
    currentUser.email,
    currentPassword
  );

  useEffect(() => {
    fetchStock();
  }, []);

  if (isLoading) {
    return <ButtonLoading></ButtonLoading>;
  }

  if (error || errorP) {
    return (
      <div>
        <p className="font-bold flex items-center justify-center mt-16 bg-green-300 p-4">
          Error! Updating Failed! Please Login Again to Update!
        </p>
      </div>
    );
  }
  if (updating || updatingP) {
    return (
      <p className="font-bold flex items-center justify-center mt-16 bg-green-300 p-4">
        Updating...
      </p>
    );
  }

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    reauthenticateWithCredential(currentUser, credential0)
      .then(() => {
        updateEmail(newEmail)
          .then(() => {
            toast.success("Email address updated Successfully");
            setNewEmail("");
            setCurrentPassword0("");
            setIsDisabled(true);
          })
          .catch((error) => {
            // Handle errors updating password
            toast.error("Error Updating Email");
            setIsDisabled(true);

          });
      })
      .catch((error) => {
        toast.error("Password Wrong");
        setIsDisabled(true);

      });
  };
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (confirmPassword === newPassword) {
      reauthenticateWithCredential(currentUser, credential)
        .then(() => {
          updatePassword(newPassword)
            .then(() => {
              toast.success("Password Updated Successfully");
              setNewPassword("");
              setCurrentPassword("");
              setConfirmPassword("");
              setIsPasswordDisabled(true);
            })
            .catch((error) => {
              toast.error("Error Updating Password!");
              setIsPasswordDisabled(true);

            });
        })
        .catch((error) => {
          toast.error("Current Password is not Correct!");
          setIsPasswordDisabled(true);

        });
    } else {
            toast.error("Passwords don't match!");
      setIsPasswordDisabled(true);

    }
  };

  return (
    <div className="mb-10">
      <p className="mx-8 mt-8 font-bold text-xl border-2 p-4 bg-green-300 rounded ">Update your Email</p>

      <div className="max-w-[30rem] w-[95%] sm:max-w-md mx-auto my-20 border-2 p-8">
        <form onSubmit={handleEmailSubmit}>
          <div className="mb-4">
            <label
              htmlFor="currentEmail"
              className="block text-gray-700 font-bold mb-2"
            >
              Current Email
            </label>
            <input
              type="email"
              id="currentEmail"
              autoComplete="useremail"
              className="shadow appearance-none border-0 rounded w-full cursor-not-allowed py-2 px-3 bg-gray-200 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={currentUser.email}
              disabled
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="currentPassword0"
              className="block text-gray-700 font-bold mb-2"
            >
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword0"
              className="shadow appearance-none border-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={currentPassword0}
              autoComplete="userpass0"
              required
              onChange={(e) => {
                setCurrentPassword0(e.target.value);
              }}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="newEmail"
              className="block text-gray-700 font-bold mb-2"
            >
              New Email
            </label>
            <input
              type="email"
              id="newEmail"
              className="shadow appearance-none border-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={newEmail}
              autoComplete="usernewemail"
              onChange={(e) => {
                !e.target.value ? setIsDisabled(true) : setIsDisabled(false);
                setNewEmail(e.target.value);
              }}
            />
          </div>

          <button
            type="submit"
            disabled={isDisabled}
            className={`text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              isDisabled
                ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-700"
            }`}
          >
            Update
          </button>
        </form>
      </div>
      <p className="m-8 font-bold text-xl border-2 p-4 bg-green-300 rounded ">Update your Password</p>
      <div className="max-w-[30rem] w-[95%] sm:max-w-md mx-auto my-4 border-2 p-8">
        <form onSubmit={handlePasswordSubmit}>
          <div className="mb-4 mt-8">
            <label
              htmlFor="currentPassword"
              className="block text-gray-700 font-bold mb-2"
            >
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              className="shadow appearance-none border-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={currentPassword}
              autoComplete="userpass"
              required
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="newPassword"
              className="block text-gray-700 font-bold mb-2"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              className="shadow appearance-none border-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={newPassword}
              autoComplete="usernewpass"
              required
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 font-bold mb-2"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="shadow appearance-none border-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={confirmPassword}
              autoComplete="userconfirmpass"
              required
              onChange={(e) => {
                !e.target.value
                  ? setIsPasswordDisabled(true)
                  : setIsPasswordDisabled(false);
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <button
            type="submit"
            disabled={isPasswordDisabled}
            className={`text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              isPasswordDisabled
                ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-700"
            }`}
          >
            Update
          </button>
        </form>
      </div>
      
      {/* CLOSE TAKING ORDER */}
      <AdminCheckbox
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        _id={_id}
      />
    </div>
  );
}
export default UpdateCredentials;
