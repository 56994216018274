import React from "react";
import { Tooltip } from "react-tooltip";
const FabricDesign = ({ fabric, selectedFabric, handleFabricChange }) => {
  return (
    <label
      htmlFor={fabric.name}
      className={`fabric-type w-full h-full max-w-[90px] max-h-[90px] md:max-w-[108px] md:max-h-[108px] w-[100%] h-[100%] rounded-full border-2 relative ${
        selectedFabric === fabric.id ? "border-[#B0D5EF] border-[6px]" : ""
      }`}
    >
      <input
        className="cursor-pointer w-[100%] h-[100%] inline-block opacity-0 absolute "
        type="radio"
        id={fabric.id}
        name="fabric"
        value={fabric.id}
        checked={selectedFabric === fabric.id}
        onChange={handleFabricChange}
        data-tooltip-id="fabric-tooltip"
        data-tooltip-content={fabric.name}
        data-tooltip-place="top"
      />
      <img
        className="w-[100%] h-[100%] rounded-full flex justify-center items-center"
        src={fabric.fabricURL}
        alt={fabric.id}
      />
      <Tooltip
        anchorSelect={`#${fabric.id}`}
        id="fabric-tooltip"
        effect="solid"
        clickable
        className="z-[20] !max-w-[350px] whitespace-normal text-justify"
      ></Tooltip>
    </label>
  );
};

export default FabricDesign;
