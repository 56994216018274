import { calculationPrice } from "./orderFunctions";

// Quantity Update Function
export const quantityUpdate = (
  data,
  state,
  finalData,
  setFinalData,
  calculationPriceData
) => {
  let newQuantity = data.quantity;
  let price;

  if (state === "increase") {
    newQuantity++;
    price = calculationPrice(
      data.dimensions,
      newQuantity,
      calculationPriceData
    );
  } else {
    if (data.quantity < 2) {
      newQuantity = 1;
    } else {
      newQuantity--;
    }

    price = calculationPrice(
      data.dimensions,
      newQuantity,
      calculationPriceData
    );
  }

  let updatedObjectIndex = finalData.findIndex(
    (obj) => obj.order === data.order
  );

  setFinalData([
    ...finalData.slice(0, updatedObjectIndex),
    {
      ...finalData[updatedObjectIndex],
      quantity: newQuantity,
      updatedPrice: price,
      updatedVAT: Number((price * 0.2).toFixed(2)),
    },
    ...finalData.slice(updatedObjectIndex + 1),
  ]);

  window.sessionStorage.setItem(
    "orders",
    JSON.stringify([
      ...finalData.slice(0, updatedObjectIndex),
      {
        ...finalData[updatedObjectIndex],
        quantity: newQuantity,
        updatedPrice: price,
        updatedVAT: Number((price * 0.2).toFixed(2)),
      },
      ...finalData.slice(updatedObjectIndex + 1),
    ])
  );
};

// Quantity Increase Function
export const plusQuantityChange = (
  id,
  finalData,
  setFinalData,
  calculationPriceData
) => {
  let selectedOrder = finalData.find((a) => a.order === id);

  quantityUpdate(
    selectedOrder,
    "increase",
    finalData,
    setFinalData,
    calculationPriceData
  );
};

// Quantity Decrease Function
export const minusQuantityChange = (
  id,
  finalData,
  setFinalData,
  calculationPriceData
) => {
  let selectedOrder = finalData.filter((a) => a.order === id);

  quantityUpdate(
    selectedOrder[0],
    "decrease",
    finalData,
    setFinalData,
    calculationPriceData
  );
};
