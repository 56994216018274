import React, { useEffect, useState } from "react";
import m2mApiClass from "../../firestoreBackendAPI";
import {toast } from 'react-toastify';
import ButtonLoading from "../../Shared/ButtonLoading";

const UpdatePrice = () => {
  const [calculationData, setCalculationData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  //   from get api......
  const fetchPrice = async () => {
    const allfetchedPrice = await m2mApiClass.getPriceData();
    setCalculationData(allfetchedPrice);
    setIsLoading(false)
  };
  useEffect(() => {
    fetchPrice();
  }, []);

  // handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      m2mApiClass.updatePriceData(calculationData[0]._id, calculationData[0]);
      toast("Price Updated Successfully!");
    } catch (error) {
      toast.error("Error Updating price!");
    } finally {
      setIsButtonDisabled(true);

    }
  };

  // handle all field submit expect thickness
  const handleChange = (event) => {
    setCalculationData([
      {
        ...calculationData[0],
        [event.target.name]: parseFloat(event.target.value),
      },
    ]);

    isButtonDisabled &&  setIsButtonDisabled(false);
  };

  // handle form submit for thickness
  const handleThicknessChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "t5":
        setCalculationData([
          {
            ...calculationData[0],
            foamThickness: {
              ...calculationData[0].foamThickness,
              t5: parseFloat(value),
            },
          },
        ]);
        break;

      case "t7":
        setCalculationData([
          {
            ...calculationData[0],
            foamThickness: {
              ...calculationData[0].foamThickness,
              t7: parseFloat(value),
            },
          },
        ]);
        break;

      case "t10":
        setCalculationData([
          {
            ...calculationData[0],
            foamThickness: {
              ...calculationData[0].foamThickness,
              t10: parseFloat(value),
            },
          },
        ]);
        break;
      default:
        break;
    }
    isButtonDisabled &&  setIsButtonDisabled(false);
  };

  if(isLoading){
    return <ButtonLoading></ButtonLoading>
  }

  return (
    <div>
      <div className="container px-4">
        <div className="container flex flex-col items-center justify-between py-2 mb-4 sm:flex-row">
          <h2 className="font-bold text-2xl mb-5 text-black p-5">
            Update Price
          </h2>
        </div>

        {/* input form */}

        <form
          onSubmit={handleSubmit}
          id="updatepriceform-elx"
          className="mx-auto flex flex-col justify-center "
        >
          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Pattern Cost:
            </label>
            <input
              type="number"
              name="patternCost"
              placeholder=""
              value={calculationData[0]?.patternCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Fabric Cost:
            </label>
            <input
              type="number"
              name="fabricCost"
              placeholder=""
              value={calculationData[0]?.fabricCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Cutting Cost:
            </label>
            <input
              type="number"
              name="cuttingCost"
              placeholder=""
              value={calculationData[0]?.cuttingCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Sewing Cost:
            </label>
            <input
              type="number"
              name="sewingCost"
              placeholder=""
              value={calculationData[0]?.sewingCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Accs Cost:
            </label>
            <input
              type="number"
              name="accsCost"
              placeholder=""
              value={calculationData[0]?.accsCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Labour Cost:
            </label>
            <input
              type="number"
              name="fillingLabourCost"
              placeholder=""
              value={calculationData[0]?.fillingLabourCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          {/* thickness cost */}
          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Foam Cost(5cm):
            </label>
            <input
              type="number"
              name="t5"
              placeholder=""
              value={calculationData[0]?.foamThickness?.t5}
              onChange={handleThicknessChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Foam Cost(7cm):
            </label>
            <input
              type="number"
              name="t7"
              placeholder=""
              value={calculationData[0]?.foamThickness?.t7}
              onChange={handleThicknessChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Foam Cost(10cm):
            </label>
            <input
              type="number"
              name="t10"
              placeholder=""
              value={calculationData[0]?.foamThickness?.t10}
              onChange={handleThicknessChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>
          {/* end thickness cost */}

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Packaging Cost:
            </label>
            <input
              type="number"
              name="packagingCost"
              placeholder=""
              value={calculationData[0]?.packagingCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Courier Cost:
            </label>
            <input
              type="number"
              name="courierCost"
              placeholder=""
              value={calculationData[0]?.courierCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Overhead Cost:
            </label>
            <input
              type="number"
              name="overHeadCost"
              placeholder=""
              value={calculationData[0]?.overHeadCost}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <div className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 ">
            <label className="label label-text font-bold text-xl sm:min-w-[13.5rem]">
              Mark Up:
            </label>
            <input
              type="number"
              name="markUp"
              placeholder=""
              value={calculationData[0]?.markUp}
              onChange={handleChange}
              className="input input-bordered w-full sm:max-w-xs"
              required
            />
          </div>

          <button disabled={isButtonDisabled} type="submit" className=" btn py-3 px-4 my-7 flex mx-auto border-0 rounded bg-green-400  hover:bg-green-500 text-white">
            Update Price
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePrice;
