import React, { useRef } from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import Navbar from "../../Shared/Navbar/Navbar";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { AiOutlineBgColors } from "react-icons/ai";
import { GiRolledCloth } from "react-icons/gi";
import { CiCalculator2 } from "react-icons/ci";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaShapes } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { GrFormClose } from "react-icons/gr";
import LogoutModal from "./Modals/LogoutModal";

const Dashboard = () => {
  const crossButtonRef = useRef(null);
  const handleSidebarItemClick = () => {
    crossButtonRef.current.click();
  };

  return (
    <div className="drawer drawer-mobile">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content scrollbar-hide !transform-none">
        <Navbar></Navbar>
        {/* <!-- Page content here --> */}
        <div className="container m-auto">
          <Outlet></Outlet>
        </div>
      </div>
      <div className="drawer-side h-[100vh]">
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>

        <ul className="relative  flex flex-col flex-wrap p-4 w-full xsm:w-80 bg-current text-base-content gap-4">
          {/* <!-- Sidebar content here --> */}
          <label
            htmlFor="my-drawer-2"
            className="absolute right-4 font-bold crossSidebar lg:hidden "
            ref={crossButtonRef}
          >
            <GrFormClose className="w-7 h-7 text-white bg-gray-300 rounded-full hover:bg-gray-200 hover:scale-105" />
          </label>

          <li className="text-white mt-8 bg-red-400 rounded  ">
            <p className=" p-[15px] ">Bespoke Admin Dashboard</p>
          </li>

          <li
            className="text-white border-b-[0.5px] border-solid border-gray-500 hover:bg-error  mt-2 text-white"
            onClick={handleSidebarItemClick}
          >
            <Link
              className="flex gap-[12px] p-[10px] items-center"
              to="/dashboard/shape"
            >
              <FaShapes />
              All Shapes
            </Link>
          </li>
          <li
            className="text-white border-b-[0.5px] border-solid border-gray-500 hover:bg-error "
            onClick={handleSidebarItemClick}
          >
            <Link
              className="flex gap-[12px] p-[10px] items-center"
              to="/dashboard/filling"
            >
              {" "}
              <AiOutlineAppstoreAdd />
              All Fillings
            </Link>
          </li>
          <li
            className="text-white border-b-[0.5px] border-solid border-gray-500 hover:bg-error "
            onClick={handleSidebarItemClick}
          >
            <Link
              className="flex gap-[12px] p-[10px] items-center"
              to="/dashboard/color"
            >
              {" "}
              <AiOutlineBgColors />
              All Colors
            </Link>
          </li>

          <li
            className="text-white border-b-[0.5px] border-solid border-gray-500 hover:bg-error "
            onClick={handleSidebarItemClick}
          >
            <Link
              className="flex gap-[12px] p-[10px] items-center"
              to="/dashboard/fabric"
            >
              {" "}
              <GiRolledCloth />
              All Fabrics
            </Link>
          </li>

          <li
            className="text-white border-b-[0.5px] border-solid border-gray-500 hover:bg-error "
            onClick={handleSidebarItemClick}
          >
            <Link
              className="flex gap-[12px] p-[10px] items-center"
              to="/dashboard/update-price"
            >
              {" "}
              <CiCalculator2 />
              Update Price
            </Link>
          </li>
          <li
            className="text-white border-b-[0.5px] border-solid border-gray-500 hover:bg-error "
            onClick={handleSidebarItemClick}
          >
            <Link
              className="flex gap-[12px] p-[10px] items-center"
              to="/dashboard/our-orders"
            >
              {" "}
              <MdOutlineProductionQuantityLimits />
              All Orders
            </Link>
          </li>
          <li
            className="text-white border-b-[0.5px] border-solid border-gray-500 hover:bg-error "
            onClick={handleSidebarItemClick}
          >
            <Link
              className="flex gap-[12px] p-[10px] items-center"
              to="/dashboard/change-credentials"
            >
              {" "}
              <MdMarkEmailUnread></MdMarkEmailUnread>
              Admin Control
            </Link>
          </li>
          <label
            htmlFor="confirmation-logout"
            className="absolute cursor-pointer bottom-2 left-6 text-red-300 text-md flex items-center py-2 w-[140px] rounded hover:bg-slate-600"
          >
            <FiLogOut className="w-8" />
            <p className="">Log Out</p>
          </label>
          {/* <div
            onClick={logout}
            className="absolute cursor-pointer bottom-2 left-6 text-red-300 text-md flex items-center py-2 w-[140px] rounded hover:bg-slate-600"
          >
            <FiLogOut className="w-8" />
            <p>Log Out</p>
          </div> */}
        </ul>
      </div>
      <LogoutModal />
    </div>
  );
};

export default Dashboard;
