import React, {useEffect } from "react";

const UpdateCheckboxFilling = (props) => {
  let { formData, setFormData,shapes,checkedItems,setCheckedItems } = props;
  let filteredCheckboxShape = [];


  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  filteredCheckboxShape = Object.keys(checkedItems).filter(
    (key) => checkedItems[key] === true
  );

  useEffect(() => {
    setFormData({
      ...formData,
      shapeType: filteredCheckboxShape,
    });
  }, [checkedItems]);

  return (
    <div>
      <div>
        <p className="mt-2 font-bold mb-1 font-Lustria">Shape Type</p>
        {shapes?.map((shapeData) => (
          <label className="mr-3" key={shapeData._id}>
            <input
              type="checkbox"
              name={shapeData.id}
             checked={checkedItems[shapeData.id] || false}
              onChange={handleChange}
              className="mr-1"
            />
            {shapeData.title}
          </label>
        ))}
      </div>
    </div>
  );
};

export default UpdateCheckboxFilling;
