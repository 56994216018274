import React, {useState, useRef } from "react";
import m2mApiClass from "../../firestoreBackendAPI";
import { toast } from "react-toastify";
import { GrFormClose } from "react-icons/gr";
import ButtonLoading from "../../Shared/ButtonLoading";
import UpdateShapeFormCircleConfirmation from "./UpdateShapeFormCircleConfirmation";

const UpdateShapeForm = ({
  fetchShapes,
  formData,
  setFormData,
}) => {
  const crossButtonRef = useRef(null);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const handleModalButton = () => {
    crossButtonRef.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    formData.id = formData.title.trim().includes(" ")
      ? formData.title.toLowerCase().split(" ").join("-")
      : formData.title.toLowerCase();

    // update shape data
    try {
      setIsActionLoading(true);
      await m2mApiClass.updateData(formData._id, formData);
      fetchShapes();
      toast("Data Updated Successfully!");
    } catch (error) {
      toast.error("Error While Updating Data!");
    } finally {
      setIsActionLoading(false);
      setFormData({
        ...formData,
        title: "",
        imageWithoutGuide: "",
        imageWithGuide: "",
        description: "",
        id: "",
        isCircle: "false",
      });
    }
    handleModalButton();
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <label
        htmlFor="update-shape-modal-elx"
        className="absolute right-3 top-1 text-2xl font-bold crossModalUpdate hover:bg-gray-200 p-2 rounded-full"
        ref={crossButtonRef}
      >
        <GrFormClose className="w-7 h-7 cursor-pointer" />
      </label>
      <form
        onSubmit={handleSubmit}
        id="updateForm-elx"
        className="form-control w-full mx-auto max-w-xs "
      >
        <label className="label label-text">Shape Name:</label>
        <input
          type="text"
          name="title"
          value={formData?.title}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
        />

        <label className="label label-text">First Image URL:</label>
        <input
          type="text"
          name="imageWithoutGuide"
          value={formData?.imageWithoutGuide}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
        />

        <label className="label label-text">Second Image URL:</label>
        <input
          type="text"
          name="imageWithGuide"
          value={formData?.imageWithGuide}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
        />

        <label className="label label-text">Description:</label>
        <input
          type="text"
          name="description"
          value={formData?.description}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
        />
        <UpdateShapeFormCircleConfirmation
          handleChange={handleChange}
          formData={formData}
        ></UpdateShapeFormCircleConfirmation>

        <button type="submit" className="btn mt-5">
          {isActionLoading ? <ButtonLoading /> : "Update Shape"}
        </button>
      </form>
    </div>
  );
};

export default UpdateShapeForm;
