import React from "react";

const AbandonOrder = () => {
  return (
    <div className="flex justify-center items-center h-[100vh]">
      <div className="flex flex-col justify-center items-center shadow-2xl max-w-[650px] bg-red-600 p-4 min-h-[650px] mx-4">
        <div>
          <p className="text-[48px] text-center text-white my-10 ">
            We are out of stock
          </p>
          <p className="w-full p-8 font-Lustria text-white text-center text-[24px]">
            "Thank you for visiting our website. We apologize for any
            inconvenience, but we are unable to take orders at this
            time. Please check back with us at a later date. If you have any
            questions or concerns, please don't hesitate to contact us."
          </p>

          <p className="text-center my-16 text-white ">
            <a
              target="_blank"
              href="https://gardenistauk.com/"
              rel="noreferrer"
            >
              GardenistaUK.com
            </a>{" "}
            •{" "}
            <a
              target="_blank"
              href="mailto:CustomOrders@GardenistaUK.com"
              rel="noreferrer"
            >
              CustomOrders@GardenistaUK.com
            </a>{" "}
            •{" "}
            <span>
              0121 232 5075 
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AbandonOrder;
