import React, { useState, useRef } from "react";
import m2mApiClass from "../../../firestoreBackendAPI";
import UpdateCheckboxFilling from "../../UpdateCheckboxFilling";
import { toast } from "react-toastify";
import { GrFormClose } from "react-icons/gr";
import ButtonLoading from "../../../Shared/ButtonLoading";

const UpdateFillingModal = ({
  formData,
  setFormData,
  fetchFilling,
  shapes,
  checkedItems,
  setCheckedItems,
  allFillingLabel,
}) => {
  const formRef = useRef(null);
  const crossButtonRef = useRef(null);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const handleModalButton = () => {
    crossButtonRef.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    formData.id = formData.title.trim().includes(" ")
      ? formData.title.toLowerCase().split(" ").join("-")
      : formData.title.toLowerCase();

    // update filling data
    try {
      setIsActionLoading(true);
      await m2mApiClass.updateFillingData(formData._id, formData);
      toast("Shape Filling Updated Successfully!");
      fetchFilling();
    } catch (error) {
      toast.error("Error While Updating Shape Filling!");
    } finally {
      formRef?.current?.reset();
      setFormData({
        ...formData,
        title: "",
        imageWithoutGuide: "",
        shapeType: [],
        description: "",
        id: "",
      });
      setIsActionLoading(false);
    }

    // close the Modal
    handleModalButton();
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <input
        type="checkbox"
        id="update-filling-modal-elx"
        className="modal-toggle"
      />
      <div className="modal modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-center">Add Filling Type</h3>
          <label
            htmlFor="update-filling-modal-elx"
            className="absolute right-3 top-1 text-2xl font-bold update-filling-cross-Modal cursor-pointer hover:bg-gray-200 p-2 rounded-full"
            ref={crossButtonRef}
          >
            <GrFormClose className="w-7 h-7" />
          </label>
          <form
            onSubmit={handleSubmit}
            id="filling-updateform-elx"
            className="form-control w-full mx-auto max-w-xs "
            ref={formRef}
          >
            <label className="label label-text">Name:</label>
            <input
              type="text"
              name="title"
              placeholder=" Enter Filling Type"
              value={formData?.title || ""}
              onChange={handleChange}
              className="input input-bordered w-full max-w-xs"
            />

            <label className="label label-text">Image URL:</label>
            <input
              type="text"
              name="imageWithoutGuide"
              placeholder=" Enter Image URL"
              value={formData?.imageWithoutGuide || ""}
              onChange={handleChange}
              className="input input-bordered w-full max-w-xs"
            />

            <label className="label label-text">Description:</label>
            <input
              type="text"
              name="description"
              placeholder=" Enter Description"
              value={formData?.description || ""}
              onChange={handleChange}
              className="input input-bordered w-full max-w-xs"
            />
            <UpdateCheckboxFilling
              formData={formData}
              setFormData={setFormData}
              shapes={shapes}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            ></UpdateCheckboxFilling>
            <button type="submit" className="btn mt-5">
            {isActionLoading ? <ButtonLoading /> : "Update Filling"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateFillingModal;
