import React, { useState, useEffect } from "react";

const HandleCheckboxFilling = ({ formData, setFormData, shapes }) => {
  let filteredCheckboxShape = [];
  let [checkedItems, setCheckedItems] = useState({});
  useEffect(() => {
    if (shapes.length > 0) {
      const tempCheckedItems = {};
      shapes.forEach((sd) => {
        tempCheckedItems[sd.id] = true;
      });
      setCheckedItems({
        ...checkedItems,
        ...tempCheckedItems,
      });
    }
  }, [shapes]);

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  filteredCheckboxShape = Object.keys(checkedItems).filter(
    (key) => checkedItems[key] === true
  );

  useEffect(() => {
    setFormData({
      ...formData,
      shapeType: filteredCheckboxShape,
    });
  }, [checkedItems]);

  return (
    <div>
      {shapes.length > 0 && (
        <div>
          <p className="mt-2 font-bold mb-1 font-Lustria">Shape Type</p>
          {shapes?.map((shapeData) => (
            <label className="mr-3" key={shapeData._id}>
              <input
                type="checkbox"
                name={shapeData.id}
                checked={checkedItems[shapeData.id] || false}
                onChange={handleChange}
                className="mr-1"
              />
              {shapeData.title}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default HandleCheckboxFilling;
