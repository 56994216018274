import React, { useContext, useRef, useState } from "react";
import { projectContext } from "../../Context/projectContext";
import { toast } from "react-toastify";
import "./dimensionDetails.css";

const DimensionDetails = ({ data, calculationPrice }) => {
  const { calculationPriceData, newCustom, setNewCustom } =
    useContext(projectContext);
  const widthInput = useRef();
  const lengthInput = useRef();
  const thicknessInput = useRef();

  const [selected, setSelected] = useState(newCustom.dimensions.hasTies);

  const changed = (e) => {
    e.preventDefault();
    setSelected(!selected);
  };

  const checkHasTies = (e) => {
    if (!selected) {
      setNewCustom({
        ...newCustom,
        dimensions: {
          ...newCustom.dimensions,
          hasTies: true,
        },
      });
    } else {
      setNewCustom({
        ...newCustom,
        dimensions: {
          ...newCustom.dimensions,
          hasTies: false,
        },
      });
    }
  };

  const selectedDimension = (key, value) => {
    if (key === "width" && data.isCircle === "true") {
      setNewCustom((prevState) => ({
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          length: value,
          width: value,
        },
      }));
    }
    if (key === "length") {
      setNewCustom((prevState) => ({
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          length: value,
        },
      }));
    }
    if (key === "width") {
      setNewCustom((prevState) => ({
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          width: value,
        },
      }));
    }
    if (key === "thickness") {
      setNewCustom((prevState) => ({
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          thickness: value,
        },
      }));
    }
    setNewCustom((prevState) => ({
      ...prevState,
      price: calculationPrice(prevState.dimensions, 1, calculationPriceData),
    }));
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row ">
        <div id="guideWithDimensions" className="w-[100%] sm:w-[60%] mx-auto">
          <img
            className="m-0 mb-3 w-[100%] lg:w-[80%] mx-auto"
            src={data.imageWithGuide}
            alt="imageWithoutGuide"
          />
        </div>
        <div
          id="dimensions"
          className="w-[100%] sm:w-[70%] lg:w-[50%] flex flex-col justify-center gap-4 mx-auto"
        >
          <table className="w-full">
            <tbody>
              <tr className="flex items-center justify-between my-2 !bg-white">
                <td className="w-[40%] text-black">
                  <label>
                    A.<span className="ml-3">Width</span>
                  </label>
                </td>
                <td className="w-[50%]">
                  <input
                    onChange={() =>
                      selectedDimension("width", widthInput.current.value)
                    }
                    ref={widthInput}
                    className="max-w-[125px] xsm:max-w-[151px] p-1 text-center bg-[#EEEEEE] border-[#B8B8B8] placeholder-[#BDBDBD] rounded-3xl focus:text-black"
                    type="number"
                    placeholder="e.g. 20"
                    defaultValue={
                      newCustom.dimensions.width
                        ? newCustom.dimensions.width
                        : "e.g. 20"
                    }
                    required
                    min="15"
                  />
                </td>
                <td className="w-[10%] ml-2 text-black">
                  <label>cm</label>
                </td>
              </tr>
              <tr className="text-[13px] text-center h-12 text-black">
                <td>(Width - Min: 15cm , Max: 120cm)</td>
              </tr>

              <tr className="flex items-center justify-between my-2">
                <td className="w-[40%] text-black">
                  <label>
                    B.<span className="ml-3">Length</span>
                  </label>
                </td>
                <td className="w-[50%]">
                  {data.isCircle === "true" ? (
                    <div
                      className="max-w-[125px] xsm:max-w-[151px] p-1 text-center bg-[#EEEEEE] border-[1px] border-[#B8B8B8] placeholder-[#BDBDBD] rounded-3xl text-gray-400"
                      onClick={() => {
                        toast.error(
                          `For ${newCustom.shape} width and length will be the same!`
                        );
                      }}
                    >
                      {newCustom.dimensions.width
                        ? newCustom.dimensions.width
                        : "e.g. 20"}
                    </div>
                  ) : (
                    <input
                      onChange={() =>
                        selectedDimension("length", lengthInput.current.value)
                      }
                      ref={lengthInput}
                      className="max-w-[125px] xsm:max-w-[151px] p-1 text-center bg-[#EEEEEE] border-[#B8B8B8] placeholder-[#BDBDBD] rounded-3xl focus:text-black"
                      type="number"
                      placeholder="e.g. 40"
                      defaultValue={
                        newCustom.dimensions.length
                          ? newCustom.dimensions.length
                          : ""
                      }
                      required
                      min="15"
                    />
                  )}
                </td>
                <td className="w-[10%] ml-2 text-black">
                  <label>cm</label>
                </td>
              </tr>
              <tr className="text-[13px] text-center h-12 text-black">
                <td>(Length - Min: 15cm , Max: 200cm)</td>
              </tr>
              <tr className="flex items-center justify-between my-2 !bg-white">
                <td className="w-[40%] text-black">
                  <label>
                    C.<span className="ml-3">Thickness</span>
                  </label>
                </td>
                <td className="w-[50%] text-black">
                  <select
                    onChange={() =>
                      selectedDimension(
                        "thickness",
                        thicknessInput.current.value
                      )
                    }
                    ref={thicknessInput}
                    className="max-w-[125px] xsm:max-w-[151px] w-[100%] p-1 !text-center bg-[#EEEEEE] border-[#B8B8B8] -webkit-appearance-none focus:text-black rounded-3xl selectTextAlign"
                    defaultValue={newCustom.dimensions.thickness}
                  >
                    <option value="5">5</option>
                    <option value="7">7</option>
                    <option value="10">10</option>
                  </select>
                </td>
                <td className="w-[10%] ml-2 text-black">
                  <label>cm</label>
                </td>
              </tr>
              <tr className="text-[13px] text-center h-12 text-black">
                <td>(Thickness available in 3 options- 5cm, 7cm, 10cm)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        id="ideaPart"
        className="flex justify-center items-center mb-[91px] mt-6"
      >
        <div className="mr-[-12px]">
          <img src="https://i.ibb.co/6DCL0DZ/Light-Bulb.png" alt="lightBulb" />
        </div>
        <div className="max-w-[70%] xsm:max-w-[80%] md:max-w-[432px] bg-[#F6F6F6] rounded-[10px]">
          <p className="text-[13px] leading-[16.67px] p-[10px] text-black ">
            All cushions will have a zip opening so the cover is easy to remove.
            The zip will run around the bottom edge of the cushion at the back.
          </p>
        </div>
      </div>
      <div
        id="hasTies"
        className="flex items-center justify-center mb-12 hidden"
      >
        <p className="mr-5 text-lg font-Lustria text-black">
          Would you like to add ties to the back of your cushion to secure onto
          your furniture?
        </p>
        <div onClick={checkHasTies}>
          <label
            className={`text-black py-2 pr-2 pl-5 border-2 border-r-2 border-[#AAAAAA] text-black font-normal yes-no cursor-pointer rounded-l-2xl ${
              selected ? "bg-[#A2C397]" : "bg-[#EEEEEE]"
            }`}
          >
            <input
              checked={selected}
              className="w-[100%] h-[100%] inline-block hidden"
              type="radio"
              name="answer"
              onChange={changed}
            />
            YES
          </label>
          <label
            className={`text-black py-2 pl-2 pr-5 border-2 border-l-2 border-[#AAAAAA] text-black font-normal yes-no cursor-pointer rounded-r-2xl ${
              !selected ? "bg-[#A2C397]" : "bg-[#EEEEEE]"
            }`}
          >
            <input
              checked={!selected}
              className="cursor-pointer w-[100%] h-[100%] inline-block hidden"
              type="radio"
              name="answer"
              onChange={changed}
            />
            NO
          </label>
        </div>
      </div>
    </div>
  );
};

export default DimensionDetails;
