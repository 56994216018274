import React from "react";
import CustomerDetailsCSV from "./CustomerDetailsCSV";

const AllCustomerDetailsCSV = ({ fetchedOrders }) => {
  let customizedCustomersDetails = [];
  
  fetchedOrders.forEach((item) => {
    let customizedCustomerDetails = {
      ...item,
      payment: item.payment ? "Paid" : "Unpaid",
      totalPrice: (Number(item.totalPrice) + Number(item.totalVAT)).toFixed(2),
      vat: Number(item.totalVAT),
      subTotalPrice: Number(item.totalPrice),
      orderDate: new Date(item.orderDate).toLocaleString("en-GB", {
        timeZone: "Europe/London",
      }),
    };
    customizedCustomersDetails.push(customizedCustomerDetails);
  });

  return (
    <div>
      <CustomerDetailsCSV detailedItem={customizedCustomersDetails} />
    </div>
  );
};

export default AllCustomerDetailsCSV;
