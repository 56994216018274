import React, { useEffect, useState } from "react";
import m2mApiClass from "../../firestoreBackendAPI";
import AddFillingModal from "./Modals/AddFillingModal";
import UpdateFillingModal from "./Modals/UpdateFillingModal";
import ConfirmationDelete from "./Modals/ConfirmationDelete";
import { Tooltip } from "react-tooltip";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineEditNote } from "react-icons/md";
import ButtonLoading from "../../Shared/ButtonLoading";

const AddFilling = () => {
  const [filling, setFilling] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let [checkedItems, setCheckedItems] = useState({});
  const allFillingLabel = []
  //  adding filling type
  const [addFormData, setAddFormData] = useState({});

  // update filling type
  const [formData, setFormData] = useState({});

  const fetchFilling = async () => {
    const allfetchedFilling = await m2mApiClass.getFillingData();
    setFilling(allfetchedFilling);
    setIsLoading(false);
  };

  const fetchShapes = async () => {
    const allfetchedShapes = await m2mApiClass.getShapeData();
    setShapes(allfetchedShapes);
  };

  useEffect(() => {
    fetchFilling();
    fetchShapes();
  }, []);

  if(filling.length>0){
    filling.forEach((fill)=>allFillingLabel.push(fill.title))
  }


  const handleUpdateData = (currentData) => {
    setFormData(currentData);
    let storeItems = currentData.shapeType?.reduce((obj, key) => {
      obj[key] = true;
      return obj;
    }, {});
    setCheckedItems(storeItems);
  };

  return (
    <div className="container px-4">
      <div className="container flex flex-col items-center justify-between py-2 my-4 sm:flex-row">
        <h2 className="font-bold text-xl sm:text-2xl mb-2 sm:mb-0 ">
          Product Filling
        </h2>
        <label
          className="p-3 px-6 pt-2 text-white bg-error rounded-full baseline font-bold cursor-pointer hover:bg-red-500"
          htmlFor="add-filling-modal"
        >
          Add Filling
        </label>
      </div>

      {isLoading ? (
        <ButtonLoading />
      ) : (
        <div className="overflow-x-auto">
          <table className="table w-full">
            {/* head */}
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Description</th>
                <th>All Types</th>
                <th>Edit / Delete</th>
              </tr>
            </thead>
            <tbody>
              {filling?.map((fill) => (
                <tr key={fill._id} id={"__".concat(fill._id)}>
                  {fill.title.length > 24 ? (
                    <td
                      id={"__1".concat(fill._id)}
                      data-tooltip-id="fill-tooltip"
                      data-tooltip-content={fill.title}
                      data-tooltip-place="top"
                      className="cursor-pointer"
                    >
                      {fill.title.slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__1".concat(fill._id)}`}
                        id="fill-tooltip"
                        effect="solid"
                        clickable
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__1".concat(fill._id)}>{fill.title} </td>
                  )}

                  {fill.imageWithoutGuide.length > 24 ? (
                    <td
                      id={"__2".concat(fill._id)}
                      data-tooltip-id="fill-tooltip"
                      data-tooltip-content={fill.imageWithoutGuide}
                      data-tooltip-place="top"
                      className="cursor-pointer"
                    >
                      {fill.imageWithoutGuide.slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__2".concat(fill._id)}`}
                        id="fill-tooltip"
                        effect="solid"
                        clickable
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__2".concat(fill._id)}>
                      {fill.imageWithoutGuide}{" "}
                    </td>
                  )}

                  {fill.description.length > 24 ? (
                    <td
                      id={"__3".concat(fill._id)}
                      data-tooltip-id="fill-tooltip"
                      data-tooltip-content={fill.description}
                      data-tooltip-place="top"
                      className="cursor-pointer"
                    >
                      {fill.description.slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__3".concat(fill._id)}`}
                        id="fill-tooltip"
                        effect="solid"
                        className="custom-tooltip-width"
                        clickable
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__3".concat(fill._id)}>{fill.description} </td>
                  )}

                  {fill.shapeType.toString().length > 24 ? (
                    <td
                      id={"__4".concat(fill._id)}
                      data-tooltip-id="fill-tooltip"
                      data-tooltip-content={fill.shapeType.toString()}
                      data-tooltip-place="top"
                      className="cursor-pointer"
                    >
                      {fill.shapeType.toString().slice(0, 24)}...
                      <Tooltip
                        anchorSelect={`#${"__4".concat(fill._id)}`}
                        id="fill-tooltip"
                        effect="solid"
                        clickable
                        className="custom-tooltip-width z-[20] !max-w-[350px] whitespace-normal text-justify"
                      ></Tooltip>
                    </td>
                  ) : (
                    <td id={"__4".concat(fill._id)}>
                      {fill.shapeType.toString()}{" "}
                    </td>
                  )}
                  <td className="flex items-center">
                    <label htmlFor="update-filling-modal-elx">
                      <MdOutlineEditNote
                        className="object-cover w-8 h-8 cursor-pointer"
                        onClick={() => handleUpdateData(fill)}
                      ></MdOutlineEditNote>
                    </label>

                    <label htmlFor="confirmation-delete">
                      <RiDeleteBin6Line
                        className="object-cover ml-2 w-6 h-6 cursor-pointer"
                        onClick={() => handleUpdateData(fill)}
                      ></RiDeleteBin6Line>
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <AddFillingModal
        formData={addFormData}
        setFormData={setAddFormData}
        fetchFilling={fetchFilling}
        allFillingLabel = {allFillingLabel}
        shapes={shapes}
      ></AddFillingModal>

      <ConfirmationDelete
        currentData={formData}
        allFetchedData={fetchFilling}
        apiMethod={m2mApiClass.deleteFillingData}
      ></ConfirmationDelete>

      <UpdateFillingModal
        formData={formData}
        setFormData={setFormData}
        fetchFilling={fetchFilling}
        shapes={shapes}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
        allFillingLabel = {allFillingLabel}
      ></UpdateFillingModal>
    </div>
  );
};

export default AddFilling;
