import React from "react";
import SummaryNavigate from "./SummaryNavigate";

const Summary = () => {
  return (
    <div>
      <SummaryNavigate></SummaryNavigate>

    </div>
  );
};

export default Summary;
