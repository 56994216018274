import { createContext, useState } from "react";

export const projectContext = createContext();

export const ProjectProvider = (props) => {
  const [shapeData, setShapeData] = useState([]);
  const [fillingData, setFillingData] = useState([]);
  const [fabricType, setFabricType] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [isStockFull, setIsStockFull] = useState([]);
  const [calculationPriceData, setCalculationPriceData] = useState([]);
  const [customerOrder, setCustomerOrder] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [newCustom, setNewCustom] = useState({
    shape: "",
    filling: "",
    dimensions: {
      width: 0,
      length: 0,
      thickness: 5,
      hasTies: false,
    },
    fabric: "",
    color: "",
    quantity: 1,
    price: "0.00",
    updatedPrice: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const value = {
    shapeData,
    setShapeData,
    fillingData,
    setFillingData,
    fabricType,
    setFabricType,
    colorData,
    setColorData,
    isStockFull,
    setIsStockFull,
    calculationPriceData,
    setCalculationPriceData,
    customerOrder,
    setCustomerOrder,
    finalData,
    setFinalData,
    newCustom,
    setNewCustom,
    isLoading,
    setIsLoading,
  };

  return (
    <projectContext.Provider value={value}>
      {props.children}
    </projectContext.Provider>
  );
};
export default ProjectProvider;
