import React, { useState, useRef } from "react";
import m2mApiClass from "../../firestoreBackendAPI";
import { toast } from "react-toastify";
import { GrFormClose } from "react-icons/gr";
import ButtonLoading from "../../Shared/ButtonLoading";
import ShapeFormCircleConfirmation from "./ShapeFormCircleConfirmation";

const AddShapeForm = ({
  formData,
  setFormData,
  fetchShapes,
  allShapeLabel,
  setFillingNotify
}) => {
  const formRef = useRef(null);
  const crossButtonRef = useRef(null);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const handleModalButton = () => {
    crossButtonRef.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      allShapeLabel.find(
        (label) => label.toLowerCase() === formData.title.toLowerCase()
      )
    ) {
      toast.error("Shape Name Already Exist! Change the Name!");
      return;
    }
    formData.id = formData.title.trim().includes(" ")
      ? formData.title.toLowerCase().split(" ").join("-")
      : formData.title.toLowerCase();

    // add shape data
    try {
      setIsActionLoading(true);
      await m2mApiClass.addShapeData(formData);
      fetchShapes();
      setFillingNotify(true)
      formRef?.current?.reset();
      setFormData({
        ...formData,
        title: "",
        imageWithoutGuide: "",
        imageWithGuide: "",
        description: "",
        id: "",
        isCircle: "false",
      });
      toast("Shape Added Successfully!");
    } catch (error) {
      toast.error("Error While Adding Shape!");
    } finally {
      setIsActionLoading(false);
    }
    handleModalButton();
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <label
        htmlFor="my-modal-6"
        className="absolute right-3 top-1 font-bold crossModal cursor-pointer hover:bg-gray-200 p-2 rounded-full"
        ref={crossButtonRef}
      >
        <GrFormClose className="w-7 h-7" />
      </label>
      <form
        onSubmit={handleSubmit}
        id="addForm-elx"
        className="form-control w-full mx-auto max-w-xs "
        ref={formRef}
      >
        <label className="label label-text">Shape Name:</label>
        <input
          type="text"
          name="title"
          placeholder=" Enter Shape Name"
          value={formData.title}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
          required
        />

        <label className="label label-text">First Image URL:</label>
        <input
          type="text"
          name="imageWithoutGuide"
          placeholder=" Image URL without size guide"
          value={formData.imageWithoutGuide}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
          required
        />

        <label className="label label-text">Second Image URL:</label>
        <input
          type="text"
          name="imageWithGuide"
          placeholder=" Image URL with size guide"
          value={formData.imageWithGuide}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
          required
        />

        <label className="label label-text">Description:</label>
        <input
          type="text"
          name="description"
          placeholder=" Enter Shape Description"
          value={formData.description}
          onChange={handleChange}
          className="input input-bordered w-full max-w-xs"
        />
        <ShapeFormCircleConfirmation
          handleChange={handleChange}
          formData={formData}
        ></ShapeFormCircleConfirmation>

        <button type="submit" className="btn mt-5">
          {isActionLoading ? <ButtonLoading /> : "Add Shape"}
        </button>
        
      </form>
    </div>
  );
};

export default AddShapeForm;
