import React, { useEffect, useState } from "react";
import m2mApiClass from "../../firestoreBackendAPI";
import ButtonLoading from "../../Shared/ButtonLoading";
import _ from "lodash";
import { BsFilterRight } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
import FilteredDatePicker from "../../Shared/DatePicker/DatePicker";
import CustomerDetailsDash from "./CustomerDetailsDash";
import AllCustomerDetailsCSV from "./AllCustomerDetailsCSV";

const AllOrders = () => {
  const [fetchedOrders, setFetchedOrders] = useState([]);
  const [saveChangedOrder, setSaveChangedOrder] = useState({});
  const [statusFilterAction, setStatusFilterAction] = useState("All Action");
  const [statusFilterPayment, setStatusFilterPayment] = useState("All Payment");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadButtonLoading, setIsLoadButtonLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const itemsPerPage = 10;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDateInputBoxVisible, setEndDateInputBoxVisible] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [firstVisible, setFirstVisible] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [orderedDataLength, setOrderedDataLength] = useState(0);

  // endTime in miliseconds
  const END_TIME_IN_MILISECONDS = 86399000;

  // filter
  let paymentStatus = null;
  if (statusFilterPayment === "Paid") {
    paymentStatus = true;
  } else if (statusFilterPayment === "Unpaid") {
    paymentStatus = false;
  } else {
  }
  const FilteredValue = {
    startedDate:
      startDate === null
        ? new Date("01-01-2023").getTime()
        : startDate.getTime(),
    endedDate:
      endDate === null
        ? new Date().getTime()
        : endDate.getTime() + END_TIME_IN_MILISECONDS,
    progress: statusFilterAction === "All Action" ? null : statusFilterAction,
    status: paymentStatus === "All Payment" ? null : paymentStatus,
  };

  const fetchOrders = async (type) => {
    const { docData: allfetchedOrders, allfetchedOrdersLength } =
      await m2mApiClass.getOrderData(
        itemsPerPage,
        FilteredValue,
        firstVisible,
        setFirstVisible,
        lastVisible,
        setLastVisible,
        type
      );

    if (fetchedOrders.length === 0) {
      setFetchedOrders(allfetchedOrders);
    } else {
      setFetchedOrders((prevData) => [...prevData, ...allfetchedOrders]);
    }
    setIsLoading(false);
    setIsLoadButtonLoading(false);
    setOrderedDataLength(allfetchedOrdersLength);
  };
  let loadDisable = false;

  // check the total length with DOM data length
  if (orderedDataLength <= fetchedOrders.length || orderedDataLength === 0) {
    loadDisable = true;
  }

  useEffect(() => {
    setIsLoading(true);
    fetchOrders();
  }, []);

  const handleNextPage = () => {
    setIsLoadButtonLoading(true);
    fetchOrders("next");
  };

  // for details modal
  const [isOpen, setIsOpen] = useState(Array(fetchedOrders.length).fill(false));

  // handle delivery
  const handleDeliveryAction = (e, indexValue, id) => {
    let updatedObjectIndex = fetchedOrders.findIndex(
      (obj, index) => obj._id === id
    );
    setSaveChangedOrder({ ...saveChangedOrder, [id]: e.target.value });

    setFetchedOrders([
      ...fetchedOrders.slice(0, updatedObjectIndex),
      {
        ...fetchedOrders[updatedObjectIndex],
        deliveryAction: e.target.value,
      },
      ...fetchedOrders.slice(updatedObjectIndex + 1),
    ]);

    setIsButtonDisabled(false);
  };

  // save the order data in db
  const handleUpdatedOrderData = async () => {
    setIsActionLoading(true);
    await m2mApiClass.updateOrderDataFromAdmin(saveChangedOrder);
    setIsButtonDisabled(true);
    setIsActionLoading(false);
  };

  // filteredValues
  const filterValuesActions = [
    "All Action",
    "Completed",
    "Processing",
    "Pending",
    "Canceled",
  ];

  const filterValuesPayment = ["All Payment", "Paid", "Unpaid"];

  // props for Date Picker
  const DatePickerProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    endDateInputBoxVisible,
    setEndDateInputBoxVisible,
  };

  // handle Filter Show Result
  const handleFilterResult = () => {
    setIsLoading(true);
    setFetchedOrders([]);
    fetchOrders();
    setIsFilterOpen(false);
  };

  return (
    <div className="container mx-auto px-4">
      {/* filtered value */}
      <div className="flex justify-end items-center my-8">
        <p
          onClick={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
          className="font-semibold bg-green-400 py-2 px-4 rounded hover:bg-green-500 cursor-pointer"
        >
          <BsFilterRight className="inline w-6 h-6" /> <span>Filter</span>
        </p>
      </div>

      {/* filter Section */}

      {isFilterOpen && (
        <div className=" mb-8 bg-slate-100 p-8 max-w-[768px] ml-auto">
          <div className="flex flex-col sm:flex-row justify-between sm:items-center">
            <div>
              <FilteredDatePicker {...DatePickerProps} />
            </div>
            <div>
              <p className="font-bold pb-1">Action:</p>
              <select
                className="block px-4 py-2 pr-8 bg-white border border-gray-400 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                value={statusFilterAction}
                onChange={(e) => setStatusFilterAction(e.target.value)}
              >
                {/* <option value="">Select a filter</option> */}
                {filterValuesActions.map((filterValue) => (
                  <option key={filterValue} value={filterValue}>
                    {filterValue}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-4 sm:mt-0">
              <p className="font-bold pb-1">Payment:</p>
              <select
                className="block px-4 py-2 pr-8 bg-white border border-gray-400 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                value={statusFilterPayment}
                onChange={(e) => setStatusFilterPayment(e.target.value)}
              >
                {/* <option value="">Select a filter</option> */}
                {filterValuesPayment.map((filterValue) => (
                  <option key={filterValue} value={filterValue}>
                    {filterValue}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <p
            onClick={handleFilterResult}
            className="w-[150px] sm:ml-auto mt-4 sm:mt-1 text-center font-semibold bg-green-400 p-4 rounded hover:bg-green-500 cursor-pointer"
          >
            Show Results
          </p>
        </div>
      )}

      <div className="flex justify-between">
        <div>
          <p className=" bg-yellow-100 rounded font-semibold pt-3 pb-1 px-4 mb-5 mx-2">
            Warning! Save if you make any changes in Action Field.
          </p>

          <p className=" bg-green-100 rounded font-semibold pt-3 pb-1 px-4 mb-5 mx-2">
            Total Order Count: {orderedDataLength}
          </p>
        </div>

        <button
          className=" btn bg-green-400 hover:bg-green-600 text-white font-bold py-2 px-6 mb-5 rounded border-0"
          onClick={handleUpdatedOrderData}
          disabled={isButtonDisabled}
        >
          {isActionLoading ? <ButtonLoading /> : "Save"}
        </button>
      </div>

      {fetchedOrders.length > 0 && (
        <div className="flex justify-end">
          <p className="inline font-semibold p-2 mb-5 mx-2">
            Export All {fetchedOrders?.length} order(s).
          </p>
          <div className="bg-[#DCFCE7] border-2 inline rounded font-semibold p-2 mb-5 mx-2 cursor-pointer">
            <AllCustomerDetailsCSV
              fetchedOrders={fetchedOrders}
            ></AllCustomerDetailsCSV>
          </div>
        </div>
      )}

      {isLoading ? (
        <ButtonLoading />
      ) : (
        <>
          <div className="overflow-x-auto min-h-[80vh]">
            <table className="table-fixed">
              <thead>
                <tr>
                  <th className="w-1/4 px-4 py-2">Order Date</th>
                  <th className="w-1/6 px-4 py-2">Name</th>
                  <th className="w-1/4 px-4 py-2">Phone Number</th>
                  <th className="w-1/6 px-4 py-2">Total Amount</th>
                  <th className="w-1/6 px-4 py-2">Payment</th>
                  <th className="w-1/2 px-4 py-2">Invoice ID</th>
                  <th className="w-1/6 px-4 py-2">Status</th>
                  <th className="w-1/6 px-4 py-2">Action</th>
                  <th className="w-1/6 px-4 py-2">Details</th>
                </tr>
              </thead>

              <tbody>
                {fetchedOrders.map((item, index) => (
                  <tr key={index} className="relative">
                    <td className="border px-4 py-2 break-words">
                      {new Date(item.orderDate).toLocaleString("en-GB", {
                        timeZone: "Europe/London",
                      })}
                    </td>
                    <td className="border px-4 py-2 break-words">
                      {item.firstName}
                    </td>

                    <td className="border px-4 py-2 break-words">
                      {item.phoneNumber}
                    </td>
                    <td className="border px-4 py-2 break-words">
                      {(
                        Number(item.totalPrice) + Number(item.totalVAT)
                      ).toFixed(2)}
                      £
                    </td>
                    <td className="border px-4 py-2 break-words">
                      <p
                        className={`${
                          item.payment
                            ? "bg-green-400 text-white"
                            : "bg-red-400 text-white"
                        } py-2 px-6 rounded-full text-center`}
                      >
                        {item.payment ? "Paid" : "Unpaid"}
                      </p>
                    </td>
                    <td className="border px-4 py-2 break-words">
                      {item?.linnworksInvoiceID || ""}
                    </td>

                    <td className="border px-4 py-2 break-words">
                      {item.deliveryAction || "pending"}
                    </td>

                    <td className="border px-4 py-2 break-words">
                      <select
                        value={item.deliveryAction}
                        onChange={(e) =>
                          handleDeliveryAction(e, index, item._id)
                        }
                      >
                        <option value="Pending">Pending</option>
                        <option value="Processing">Processing</option>
                        <option value="Completed">Completed</option>
                        <option value="Canceled">Canceled</option>
                      </select>
                    </td>

                    <td className="border px-4 py-2">
                      <button
                        className="bg-slate-200 hover:bg-green-300 text-white font-bold py-2 px-2 rounded"
                        onClick={() => {
                          const newIsOpen = [...isOpen];
                          newIsOpen[index] = !newIsOpen[index];
                          setIsOpen(newIsOpen);
                        }}
                      >
                        <CgDetailsMore className="w-6 h-6 text-black"></CgDetailsMore>
                      </button>

                      {isOpen[index] && (
                        <CustomerDetailsDash item={item}></CustomerDetailsDash>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="m-4 text-center font-bold">
              Showing {fetchedOrders.length} from {orderedDataLength} orders
            </p>

            {/* pagination Button */}
            <div className="flex justify-center gap-5 my-20">
              <button
                disabled={loadDisable}
                className={`rounded px-6 py-2  ${
                  loadDisable
                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                    : "bg-green-400 hover:bg-green-500 text-white "
                } `}
                onClick={handleNextPage}
              >
                {isLoadButtonLoading ? (
                  <ButtonLoading />
                ) : (
                  <p>Load More &raquo;</p>
                )}
              </button>
            </div>
            {/* pagination btn end */}
          </div>
        </>
      )}
    </div>
  );
};
export default AllOrders;
