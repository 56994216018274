import React, { useEffect,  useState } from "react";
import m2mApiClass from "../../firestoreBackendAPI";
import { toast } from "react-toastify";
import ConfirmationDelete from "./Modals/ConfirmationDelete";
import { RiDeleteBin6Line } from "react-icons/ri";
import ButtonLoading from "../../Shared/ButtonLoading";


const AddColor = () => {
  const [colorData, setColorData] = useState([]);
  const [prevColorData, setPrevColorData] = useState([]);
  const [colorObj, setColorObj] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [storegetColorsLength, setStoregetColorsLength] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  let currentColorsLength = 0;
  const allColorLabel = []
  //   from get api......
  const fetchColor = async () => {
    const allfetchedColor = await m2mApiClass.getColorData();
    setColorData(allfetchedColor);
    setPrevColorData(allfetchedColor);
    setIsLoading(false);
    setStoregetColorsLength(allfetchedColor.length);
  };
  useEffect(() => {
    fetchColor();
  }, []);

if(prevColorData.length>0){
  prevColorData.forEach((color)=>allColorLabel.push(color.name))
}

  const handleUpdateData = (currentObj, index) => {
    setColorObj(currentObj);
    setCurrentIndex(index);
  };

  const handleAddColor = (e) => {
    e.preventDefault();

    setColorData([...colorData, { name: "", hexCode: "", id: "" }]);
    setIsButtonDisabled(false);
  };

  const handleHexChange = (value, indexValue, fieldName) => {
    let updatedObjectIndex = colorData.findIndex(
      (obj, index) => index === indexValue
    );

    if (fieldName === "color") {
      setColorData([
        ...colorData.slice(0, updatedObjectIndex),
        {
          ...colorData[updatedObjectIndex],
          name: value,
          id: value.trim().includes(" ")
            ? value.toLowerCase().split(" ").join("-")
            : value.toLowerCase(),
        },
        ...colorData.slice(updatedObjectIndex + 1),
      ]);
    } else {
      setColorData([
        ...colorData.slice(0, updatedObjectIndex),
        {
          ...colorData[updatedObjectIndex],
          hexCode: value,
        },
        ...colorData.slice(updatedObjectIndex + 1),
      ]);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    currentColorsLength = colorData.length - storegetColorsLength;
    if(currentColorsLength > 0) {

      let updatedColorsArray = colorData.slice(-currentColorsLength);
      updatedColorsArray.forEach((eachColor) => {
        if (allColorLabel.find((label) => label.toLowerCase() === eachColor.name.toLowerCase())) {
          toast.error(`${eachColor.name} Color Already Exist! Change the Name`);
          return;
        }
        try {
          m2mApiClass.addColorData(eachColor);
          toast(`${eachColor.name} Color is Added`);
          fetchColor();
        } catch (error) {
      
          toast.error("Error While Adding Color");
        } finally {
          setIsButtonDisabled(true);
        }
      });

    }else{
      toast.error("Nothing to add");
      setIsButtonDisabled(true);
    }

  };

  return (
    <div>
      <div className="container px-4">
        <div className="container flex flex-col items-center justify-between py-2 mb-4 sm:flex-row">
          <h2 className="font-bold text-2xl mb-5 text-black p-8">
            Add Color
          </h2>
        </div>

        {/* input form */}
        {isLoading ? (
          <ButtonLoading />
        ) : (
          <form
            onSubmit={handleSubmit}
            id="addcolorform-elx"
            className="mx-auto flex flex-col justify-center "
          >
            {colorData?.map((color, index) => (
              <div
                key={index}
                className="flex mb-4 flex-col sm:flex-row mx-auto justify-center gap-x-5 md:gap-x-10 gap-y-5 "
              >
                <input
                  type="text"
                  placeholder="Add Color Name"
                  value={color.name}
                  required
                  index={index}
                  onChange={(e) =>
                    handleHexChange(e.target.value, index, "color")
                  }
                  className="input input-bordered w-full sm:max-w-xs text-xl"
                  disabled={typeof color._id != "undefined" && true}
                ></input>
                <input
                  type="text"
                  name=""
                  placeholder="#000000"
                  required
                  value={color.hexCode}
                  index={index}
                  onChange={(e) =>
                    handleHexChange(e.target.value, index, "hexCode")
                  }
                  className="input input-bordered w-full sm:max-w-xs"
                  disabled={typeof color._id != "undefined" && true}
                />
                <label htmlFor="confirmation-delete">
                  <RiDeleteBin6Line
                    className="object-cover ml-2 sm:mt-2 w-6 h-6 cursor-pointer"
                    onClick={() => handleUpdateData(color, index)}
                  ></RiDeleteBin6Line>
                </label>
              </div>
            ))}

            <div className="inline-block mx-auto">
              <button
                className="btn my-7 mx-auto min-w-[7.5rem] mr-5"
                onClick={handleAddColor}
              >
                Add Color
              </button>
              <button
                type="submit"
                disabled={isButtonDisabled}
                className="btn my-7 mx-auto min-w-[7.5rem]"
              >
                Save
              </button>
            </div>
          </form>
        )}

        <ConfirmationDelete
          currentData={colorObj}
          allFetchedData={fetchColor}
          apiMethod={m2mApiClass.deleteColorData}
          index={currentIndex}
          optionalArrayofObject={colorData}
          optionalArrayofObjectFunc={setColorData}
        ></ConfirmationDelete>
      </div>
    </div>
  );
};

export default AddColor;
