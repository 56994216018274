import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { AiOutlineBgColors } from 'react-icons/ai'
import { GiRolledCloth } from 'react-icons/gi'
import { CiCalculator2 } from 'react-icons/ci'
import { MdOutlineProductionQuantityLimits } from 'react-icons/md'
import { CgShapeTriangle } from "react-icons/cg";


const SummaryNavigate = () => {
  return (
    <div>
      <div className=" mt-10 mb-20">
        <h2 className="text-2xl font-bold mx-4 p-4 mb-8 border-b-[2px]">Navigate Sections</h2>

        <div className="flex flex-wrap justify-center gap-8 max-w-[1250px] m-auto">

          <div className="relative flex justify-center flex-col w-[80%] bg-[#EA5455] mx-4 sm:w-1/2 md:w-1/4 bg-border border-gray-300 shadow-lg rounded-lg ">
            <CgShapeTriangle className=" bg-white opacity-[0.1] mx-auto w-full h-full" ></CgShapeTriangle>
            
            
            <Link to="/dashboard/shape" className="absolute right-0 left-0  mx-auto   w-[160px] xl:w-[180px] p-4 bg-white rounded cursor-pointer hover:bg-slate-100">
              <h3 className="font-semibold text-gray-800 text-lg text-center">
                Add Shape
              </h3>
            </Link>

          </div>
          

          <div className="relative flex justify-center flex-col w-[80%] bg-[#FFBF9B] mx-4 sm:w-1/2 md:w-1/4 bg-border border-gray-300 shadow-lg rounded-lg ">
            <AiOutlineAppstoreAdd className=" bg-white opacity-[0.1] mx-auto w-full h-full" ></AiOutlineAppstoreAdd>
            
            <Link to="/dashboard/filling" className="absolute right-0 left-0  mx-auto   w-[160px] xl:w-[180px] p-4 bg-white rounded cursor-pointer hover:bg-slate-100">
              <h3 className="font-semibold text-gray-800 text-lg text-center">
                Add Fillings
              </h3>
            </Link>

          </div>

          <div className="relative flex justify-center flex-col w-[80%] bg-[#0E8388] mx-4 sm:w-1/2 md:w-1/4 bg-border border-gray-300 shadow-lg rounded-lg ">
            <AiOutlineBgColors className=" bg-white opacity-[0.1] mx-auto w-full h-full" ></AiOutlineBgColors>
            
            <Link to="/dashboard/color" className="absolute right-0 left-0  mx-auto  w-[160px] xl:w-[180px] p-4 bg-white rounded cursor-pointer hover:bg-slate-100">
              <h3 className="font-semibold text-gray-800 text-lg text-center">
                Add Color
              </h3>
            </Link>

          </div>

          {/* last 3 div */}

          <div className="relative flex justify-center flex-col w-[80%] bg-[#F7C04A] mx-4 sm:w-1/2 md:w-1/4 bg-border border-gray-300 shadow-lg rounded-lg ">
            <GiRolledCloth className=" bg-white opacity-[0.1] mx-auto w-full h-full" ></GiRolledCloth>
            
            <Link to="/dashboard/fabric" className="absolute right-0 left-0  mx-auto  w-[160px] xl:w-[180px] p-4 bg-white rounded cursor-pointer hover:bg-slate-100">
              <h3 className="font-semibold text-gray-800 text-lg text-center">
                Add Fabrics
              </h3>
            </Link>

          </div>


          <div className="relative flex justify-center flex-col w-[80%] bg-[#C7E8CA] mx-4 sm:w-1/2 md:w-1/4 bg-border border-gray-300 shadow-lg rounded-lg ">
            <CiCalculator2 className=" bg-white opacity-[0.1] mx-auto w-full h-full" ></CiCalculator2>
            
            <Link to="/dashboard/update-price" className="absolute right-0 left-0  mx-auto  w-[160px] xl:w-[180px] p-4 bg-white rounded cursor-pointer hover:bg-slate-100">
              <h3 className="font-semibold text-gray-800 text-lg text-center">
                Update Cost
              </h3>
            </Link>

          </div>


          <div className="relative flex justify-center flex-col w-[80%] bg-[#95BDFF] mx-4 sm:w-1/2 md:w-1/4 bg-border border-gray-300 shadow-lg rounded-lg ">
            <MdOutlineProductionQuantityLimits className=" bg-white opacity-[0.1] mx-auto w-full h-full" ></MdOutlineProductionQuantityLimits>
            
            <Link to="/dashboard/our-orders" className="absolute right-0 left-0  mx-auto  w-[160px] xl:w-[180px] p-4 bg-white rounded cursor-pointer hover:bg-slate-100">
              <h3 className="font-semibold text-gray-800 text-lg text-center">
                All Orders
              </h3>
            </Link>

          </div>




        </div>


        


      </div>
    </div>
  );
};

export default SummaryNavigate;
