import React, { useState } from "react";
import { toast } from "react-toastify";
import { useLocation,useNavigate } from "react-router-dom";
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../../firestoreConfig";

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  let searchParams = new URLSearchParams(location.search);
  let oobcode = searchParams.get("oobCode");
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (confirmPassword === newPassword) {
        setIsLoading(true)
      confirmPasswordReset(auth,oobcode, newPassword)
        .then(() => {
          toast.success(
            "Password Reset Done! Now you can login with your new Password!"
          );
          navigate("/admin")
        })
        .catch((error) => {
          toast.error("Failed! Invalid Password");
        }).finally(()=>{
            setIsLoading(false)

        })
    } else {
            toast.error("Passwords don't match!");
    }
  };

  return (
    <div className="flex items-center h-screen">
      <div className="max-w-[30rem] w-[95%] sm:max-w-md mx-auto my-4 border-2 p-8">
        <form onSubmit={handlePasswordSubmit}>
          <div className="my-4">
            <label
              htmlFor="newPassword"
              className="block text-gray-700 font-bold mb-2"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              className="shadow appearance-none border-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={newPassword}
              autoComplete="userpass"
              required
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 font-bold mb-2"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="shadow appearance-none border-1 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={confirmPassword}
              autoComplete="userconfirmpass"
              required
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <button
            type="submit"
            className="text-white bg-green-500 mx-auto font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {isLoading? "Updating..." : "Reset Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
