import React from "react";
import { Tooltip } from "react-tooltip";

const ColorDesign = ({ color, selectedColor, handleColorChange }) => {
  return (
    <label
      htmlFor={color.name}
      className={`color-type w-[90px] h-[90px] md:w-[108px] md:h-[108px] rounded-full border-2  relative ${
        selectedColor === color.id ? "border-[#B0D5EF] border-[6px]" : ""
      }`}
    >
      <input
        className="cursor-pointer w-[100%] h-[100%] inline-block opacity-0 absolute"
        type="radio"
        id={color.id}
        name="color"
        value={color.id}
        checked={selectedColor === color.id}
        onChange={handleColorChange}
        data-tooltip-id="color-tooltip"
        data-tooltip-content={color.name}
        data-tooltip-place="top"
      />
      <div
        className="w-[100%] h-[100%] rounded-full flex justify-center items-center"
        style={{ backgroundColor: `${color.hexCode}` }}
      ></div>
      <Tooltip
        anchorSelect={`#${color.id}`}
        id="color-tooltip"
        effect="solid"
        clickable
        className="z-[20] !max-w-[350px] whitespace-normal text-justify"
      ></Tooltip>
    </label>
  );
};

export default ColorDesign;
