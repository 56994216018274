import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { projectContext } from "../../Context/projectContext";
import m2mApiClass from "../../firestoreBackendAPI";
import ButtonLoading from "../../Shared/ButtonLoading";
import OrderSummary from "../Summary/OrderSummary";
const axios = require("axios");

const CustomerDetails = () => {
  const { finalData, setFinalData } = useContext(projectContext);
  const firstName = useRef();
  const lastName = useRef();
  const email = useRef();
  const address = useRef();
  const city = useRef();
  const region = useRef();
  const country = useRef();
  const zipCode = useRef();
  const phoneNumber = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  let q;

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("orders"))) {
      setFinalData(JSON.parse(sessionStorage.getItem("orders")));
    }
  }, [setFinalData]);

  const handleCustomerDetails = async (e) => {
    e.preventDefault();

    const customerData = {
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      email: email.current.value,
      address: address.current.value,
      city: city.current.value,
      region: region.current.value,
      country: country.current.value,
      zipCode: zipCode.current.value,
      phoneNumber: phoneNumber.current.value,
      orderDetails: finalData,
      payment: false,
      paymentDetails: {},
      totalQuantity: finalData.reduce((total, obj) => obj.quantity + total, 0),
      totalPrice: Number(
        finalData.reduce((total, obj) => obj.updatedPrice + total, 0).toFixed(2)
      ),
      deliveryAction: "Pending",
      totalVAT: Number(
        finalData
          .reduce((total, obj) => Number(obj.updatedVAT) + total, 0)
          .toFixed(2)
      ),
      linnworksInvoiceID: "",
    };

    // add filling data
    try {
      setIsLoading(true);
      m2mApiClass.addOrderData(customerData).then(async (docRef) => {
        if (docRef.isPriceMatched === "true") {
          window.sessionStorage.setItem("orderID", docRef._id);

          window.sessionStorage.setItem(
            "customerDetails",
            JSON.stringify(customerData)
          );

          navigate("/checkout");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("Don't change data from session storage!", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
    }
  };

  return (
    <div className="overflow-y-hidden bg-white">
      <div className="flex justify-center items-center py-9 lg:py-16 md:py-12 px-4 md:px-6 lg:px-20 ">
        <div className="flex w-full flex-col gap-6 md:flex-row justify-center items-center  order-last">
          <div className="flex w-full flex-col justify-start lg:items-start">
            <div>
              <p className="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800 font-Lustria text-black">
                Customer Details
              </p>
            </div>
            <div>
              <div className="mt-2">
                <Link
                  to="/overview"
                  className="text-base leading-4 underline hover:text-gray-800 text-gray-600 text-black"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Back to basket
                </Link>
              </div>
              <div className="mt-12">
                <p className="text-xl font-semibold leading-5 text-gray-800 font-Lustria text-black">
                  Shipping Details
                </p>
              </div>
              <form
                className="customerDetailsForm"
                onSubmit={handleCustomerDetails}
              >
                <div className="mt-8 flex flex-col justify-start items-start w-full ">
                  <div className="relative w-full my-3">
                    <input
                      type="text"
                      id="first_name"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      ref={firstName}
                      required
                    />
                    <label
                      htmlFor="first_name"
                      className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      First Name<span className="text-red-500 mt-4">*</span>
                    </label>
                  </div>

                  <div className="relative w-full my-3">
                    <input
                      type="text"
                      id="last_name"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      ref={lastName}
                      required
                    />
                    <label
                      htmlFor="last_name"
                      className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Last Name<span className="text-red-500 mt-4">*</span>
                    </label>
                  </div>

                  <div className="relative w-full my-3">
                    <input
                      type="email"
                      id="email"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      ref={email}
                      required
                    />
                    <label
                      htmlFor="email"
                      className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Email<span className="text-red-500 mt-4">*</span>
                    </label>
                  </div>

                  <div className="relative w-full my-3">
                    <input
                      type="text"
                      id="address"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      ref={address}
                      required
                    />
                    <label
                      htmlFor="address"
                      className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Address<span className="text-red-500 mt-4">*</span>
                    </label>
                  </div>

                  <div className="sm:flex block justify-between w-full gap-6">
                    <div className="relative w-full my-3">
                      <input
                        type="text"
                        id="city"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        ref={city}
                        required
                      />
                      <label
                        htmlFor="city"
                        className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        City<span className="text-red-500 mt-4">*</span>
                      </label>
                    </div>
                    <div className="relative w-full my-3">
                      <input
                        type="text"
                        id="region"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        ref={region}
                        required
                      />
                      <label
                        htmlFor="region"
                        className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Region<span className="text-red-500 mt-4">*</span>
                      </label>
                    </div>
                  </div>

                  <div className="sm:flex block justify-between w-full gap-6">
                    <div className="relative w-full my-3">
                      <select
                        type="text"
                        id="country"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        ref={country}
                        required
                      >
                        <option value="UK">United Kingdom</option>
                      </select>
                      <label
                        htmlFor="country"
                        className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Country<span className="text-red-500 mt-4">*</span>
                      </label>
                    </div>
                    <div className="relative w-full my-3">
                      <input
                        type="text"
                        id="zipCode"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        ref={zipCode}
                        required
                      />
                      <label
                        htmlFor="zipCode"
                        className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Zip Code<span className="text-red-500 mt-4">*</span>
                      </label>
                    </div>
                  </div>

                  <div className="relative w-full my-3">
                    <input
                      type="number"
                      id="phoneNumber"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      ref={phoneNumber}
                    />
                    <label
                      htmlFor="phoneNumber"
                      className="absolute text-sm text-gray-500 dark:text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Phone Number
                    </label>
                  </div>

                  <div className="text-black">
                    <label>
                      <input
                        type="checkbox"
                        required
                        className="mr-5 focus:ring-0"
                      />
                      By placing this order, you have read, understood and
                      acknowledged the
                      <a
                        className="underline ml-1"
                        href="https://gardenistauk.com/pages/made-to-measure-terms-and-conditions"
                        target="_blank"
                      >
                        Bespoke terms and conditions
                      </a>
                      .
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 mt-8 text-base font-medium focus:ring-2 focus:ring-focus:ring-gray-800 leading-4 hover:bg-black py-4 w-full text-white bg-gray-800"
                >
                  {isLoading ? <ButtonLoading /> : "Proceed to payment"}
                </button>
              </form>
            </div>
            <div className="mt-4 flex justify-start items-center w-full">
              <Link
                to="/overview"
                className="text-base leading-4 underline  hover:text-gray-800 text-gray-600 text-black"
                onClick={() => window.scrollTo(0, 0)}
              >
                Back to basket
              </Link>
            </div>
          </div>
          <OrderSummary order={finalData} />
        </div>
      </div>
    </div>
  );
};
export default CustomerDetails;

// token = 87f6ef5836878027f100b80a0194bc83
