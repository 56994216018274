import React from "react";
import AddShapeForm from "../../../Shared/Forms/AddShapeForm";

const AddShapeModal = ({
  formData,
  setFormData,
  fetchShapes,
  allShapeLabel,
  setFillingNotify,
}) => {
  return (
    <div>
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-center">Add a Single Shape</h3>
          <AddShapeForm
            formData={formData}
            setFormData={setFormData}
            fetchShapes={fetchShapes}
            allShapeLabel={allShapeLabel}
            setFillingNotify={setFillingNotify}
          ></AddShapeForm>
        </div>
      </div>
    </div>
  );
};

export default AddShapeModal;
