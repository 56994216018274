import React from "react";
import { CSVLink } from "react-csv";
import { AiOutlineCloudDownload } from "react-icons/ai";

const CustomerDetailsCSV = ({ detailedItem }) => {
  let headers = [
    { label: "Order Date", key: "orderDate" },
    { label: "Invoice ID", key: "linnworksInvoiceID" },
    {
      label: "Transaction ID",
      key: "paymentDetails.purchase_units[0].payments.captures[0].id",
    },
    { label: "First_name", key: "firstName" },
    { label: "Last_name", key: "lastName" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "Country", key: "country" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "phoneNumber" },
    { label: "Region", key: "region" },
    { label: "ZIP Code", key: "zipCode" },
    { label: "Progress", key: "deliveryAction" },
    { label: "Payment", key: "payment" },
    { label: "Total Quantity", key: "totalQuantity" },
    { label: "Sub-total Price", key: "subTotalPrice" },
    { label: "VAT", key: "vat" },
    { label: "Total Price", key: "totalPrice" },
  ];

  let customOrderDetails = detailedItem[0].orderDetails;

  for (let i = 0; i < customOrderDetails.length; i++) {
    headers.push({
      label: `${customOrderDetails[i].title} Shape`,
      key: `orderDetails[${i}].shape`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Fabric`,
      key: `orderDetails[${i}].fabric`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Thickness`,
      key: `orderDetails[${i}].dimensions.thickness`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Width`,
      key: `orderDetails[${i}].dimensions.width`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Length`,
      key: `orderDetails[${i}].dimensions.length`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Ties`,
      key: `orderDetails[${i}].dimensions.hasTies`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Color`,
      key: `orderDetails[${i}].color`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Filling`,
      key: `orderDetails[${i}].filling`,
    });
    headers.push({
      label: `${customOrderDetails[i].title},Unit Price`,
      key: `orderDetails[${i}].price`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Quantity`,
      key: `orderDetails[${i}].quantity`,
    });
    headers.push({
      label: `${customOrderDetails[i].title} Total Price`,
      key: `orderDetails[${i}].updatedPriceWithVAT`,
    });
  }

  return (
    <div>
      <CSVLink
        filename={`Customer_Order_Details.csv`}
        data={detailedItem}
        headers={headers}
      >
        Export <AiOutlineCloudDownload className="w-6 h-6 inline-block" />
      </CSVLink>
    </div>
  );
};

export default CustomerDetailsCSV;
