import { PayPalButtons } from '@paypal/react-paypal-js';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { projectContext } from '../../Context/projectContext';
import m2mApiClass from '../../firestoreBackendAPI';
import emailjs from '@emailjs/browser';
const axios = require('axios');

const Payment = () => {
  const [error, setError] = useState(null);
  const [invoiceId, setInvoiceId] = useState('');
  const navigate = useNavigate();
  const { customerOrder, setCustomerOrder, setIsLoading } =
    useContext(projectContext);
  const date = new Date();

  // Alert for user
  function beforeUnloadHandler(e) {
    let confirmationMessage = 'Leave Site? Changes you made may not be saved.';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem('customerDetails'))) {
      setCustomerOrder(JSON.parse(sessionStorage.getItem('customerDetails')));
    }
  }, []);

  let q;

  let details_units = {
    purchase_units: [
      {
        description: 'Custom Cushion',
        amount: {
          value: (customerOrder?.totalPrice + customerOrder?.totalVAT).toFixed(
            2
          ),
          currency_code: 'GBP',
          breakdown: {
            item_total: {
              currency_code: 'GBP',
              value: (
                customerOrder?.totalPrice + customerOrder?.totalVAT
              ).toFixed(2),
            },
          },
        },
        items: customerOrder?.orderDetails?.map(
          (order) =>
            (q = {
              name: order?.title + ' x ' + order?.quantity,
              quantity: 1,
              unit_amount: {
                currency_code: 'GBP',
                value: (
                  order?.updatedPrice + Number(order?.updatedVAT)
                ).toFixed(2),
              },
            })
        ),
      },
    ],
  };

  const handleApprove = async (orderCompletedData) => {
    // setIsPaid(true);
    setInvoiceId(orderCompletedData.purchase_units[0].payments.captures[0].id);
    if (orderCompletedData.status === 'COMPLETED') {
      try {
        navigate('/thank-you');
        //linnworks Order placement
        let linnworksOrderData = [
          {
            UseChannelTax: true,
            pkOrderId:
              orderCompletedData.purchase_units[0].payments.captures[0].id,
            AutomaticallyLinkBySKU: true,
            Site: 'Gardenista',
            MatchPostalServiceTag: '',
            PostalServiceName: 'Standard',
            SavePostalServiceIfNotExist: false,
            MatchPaymentMethodTag: 'Main',
            PaymentMethodName: 'Bank Transfer',
            SavePaymentMethodIfNotExist: false,
            MappingSource: 'Gardenista',
            OrderState: 'None',
            PaymentStatus: 'Paid',
            OrderItems: customerOrder.orderDetails.map(
              (order) =>
                (q = {
                  TaxCostInclusive: true,
                  UseChannelTax: true,
                  PricePerUnit:
                    (order.updatedPrice + Number(order.updatedVAT)) /
                    order.quantity,
                  Qty: order.quantity,
                  TaxRate: 0.0,
                  LineDiscount: 0.0,
                  ItemNumber: order.order,
                  ChannelSKU: 'Made To Measure',
                  IsService: false,
                  ItemTitle: order.title,
                  Options: [
                    {
                      Property: 'Details',
                      Value:
                        order.title +
                        ' Shape: ' +
                        order.shape.toUpperCase() +
                        ' Filling: ' +
                        order.filling.toUpperCase() +
                        ' Dimensions: Length: ' +
                        order.dimensions.length +
                        ' cm' +
                        ' Width: ' +
                        order.dimensions.width +
                        ' cm' +
                        ' Thickness: ' +
                        order.dimensions.thickness +
                        ' cm' +
                        ' Has Ties: ' +
                        order.dimensions.hasTies +
                        ' Color: ' +
                        order.color.toUpperCase() +
                        ' Fabric: ' +
                        order.fabric.toUpperCase() +
                        ' Quantity: ' +
                        order.quantity,
                    },
                  ],
                })
            ),
            ExtendedProperties: [],
            Notes: [],
            Source: 'Direct',
            SubSource: 'CUSTOM',
            ChannelBuyerName: customerOrder.firstName,
            ReferenceNumber:
              orderCompletedData?.purchase_units[0]?.payments.captures[0].id,
            ExternalReference:
              orderCompletedData?.purchase_units[0]?.payments.captures[0].id,
            SecondaryReferenceNumber:
              orderCompletedData?.purchase_units[0]?.payments.captures[0].id,
            Currency: 'GBP',
            ConversionRate: 0.0,
            ReceivedDate: new Date(),
            DispatchBy: '2019-07-08T14:31:53.5191275+01:00',
            PaidOn: new Date(),
            PostalServiceCost: 0.0,
            PostalServiceTaxRate: 0.0,
            PostalServiceDiscount: 0.0,
            Discount: 0.0,
            DiscountType: 'AllEvenly',
            DiscountTaxType: 'DeductAfterTax',
            BillingAddress: {
              MatchCountryCode: 'UK',
              MatchCountryName: 'United Kingdom',
              FullName: customerOrder.firstName + ' ' + customerOrder.lastName,
              Address1:
                customerOrder.city +
                ',' +
                customerOrder.region +
                ',' +
                customerOrder.zipCode +
                ',' +
                customerOrder.country,
              Town: customerOrder.city,
              Region: customerOrder.region,
              PostCode: customerOrder.zipCode,
              Country: customerOrder.country,
              PhoneNumber: customerOrder.phoneNumber,
              EmailAddress: customerOrder.email,
            },
            DeliveryAddress: {
              MatchCountryCode: 'UK',
              MatchCountryName: 'United Kingdom',
              FullName: customerOrder.firstName + ' ' + customerOrder.lastName,
              Address1:
                customerOrder.city +
                ',' +
                customerOrder.region +
                ',' +
                customerOrder.zipCode +
                ',' +
                customerOrder.country,
              Town: customerOrder.city,
              Region: customerOrder.region,
              PostCode: customerOrder.zipCode,
              Country: customerOrder.country,
              PhoneNumber: customerOrder.phoneNumber,
              EmailAddress: customerOrder.email,
            },
            DeliveryStartDate: new Date(),
            DeliveryEndDate: new Date(
              new Date().setDate(new Date().getDate() + 14)
            ),
            OrderIdentifierTags: ['sample string 1', 'sample string 2'],
            ForceReSaveFulfilledOrder: true,
          },
        ];

        const linnWorksFunction = async () => {
          const authenticationResponse = await axios.post(
            'https://api.linnworks.net//api/Auth/AuthorizeByApplication',
            new URLSearchParams({
              applicationId: process.env.REACT_APP_LINNWORKS_applicationId,
              applicationSecret:
                process.env.REACT_APP_LINNWORKS_applicationSecret,
              token: process.env.REACT_APP_LINNWORKS_token,
            })
          );

          const orderLocationId = await axios.get(
            `https://eu-ext.linnworks.net/api/Inventory/GetStockLocations`,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `${authenticationResponse.data.Token}`,
              },
            }
          );

          const orderPlacementResponse = await axios.post(
            `https://eu-ext.linnworks.net/api/Orders/CreateOrders`,
            new URLSearchParams({
              orders: JSON.stringify(linnworksOrderData),
            }),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `${authenticationResponse.data.Token}`,
              },
            }
          );

          const getLinnworksOrderId = await axios.get(
            'https://eu-ext.linnworks.net/api/Orders/GetOrder',
            {
              headers: {
                Authorization: `${authenticationResponse.data.Token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              params: {
                orderId: `${orderPlacementResponse.data[0]}`,
                fulfilmentLocationId: orderLocationId.data[0].StockLocationId,
                loadItems: true,
                loadAdditionalInfo: true,
              },
            }
          );

          // setCustomerOrder((prevState) => ({
          //   ...prevState,
          // }));

          const updatedCustomerDetails = {
            ...customerOrder,
            payment: true,
            paymentDetails: orderCompletedData,
            linnworksInvoiceID: getLinnworksOrderId?.data.NumOrderId,
          };

          if (sessionStorage.getItem('orderID')) {
            m2mApiClass.updateOrderData(
              sessionStorage.getItem('orderID'),
              updatedCustomerDetails
            );
          }

          console.log(updatedCustomerDetails);

          await axios.post(
            `https://eu-ext.linnworks.net/api/Orders/ChangeOrderTag`,
            {
              orderIds: [`${orderPlacementResponse.data[0]}`],
              tag: 'null',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${authenticationResponse.data.Token}`,
              },
            }
          );

          //Invoice Details
          var templateParams = {
            name: customerOrder.firstName,
            email: customerOrder.email,
            my_html: `
          <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    <html lang="en">

      <head></head>
      <div id="__react-email-preview" style="display:none;overflow:hidden;line-height:1px;opacity:0;max-height:0;max-width:0">Order Receipt<div></div>
      </div>

      <body style="font-family:Helvetica Neue,Helvetica,Arial,sans-serif;background-color:#ffffff">
        <table align="center" role="presentation" cellSpacing="0" cellPadding="0" border="0" width="100%" style="max-width:50em;margin:0 auto;padding:20px 0 48px;width:100%;">
          <tr style="width:100%">
            <td>
              <table align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation" width="100%">
                <tbody>
                  <tr>
                    <td>

                    <td align="right" style="display:table-cell">
                    <a style="cursor: default;"><img width="250px"
                    src="https://cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_772x200.png?v=1614379185"
                    alt="logo"
                    srcset="
                      https://cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_772x200.png?v=1614379185  1x,
                      https://cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_1544x400.png?v=1614379185 2x,
                      https://cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_2316x600.png?v=1614379185 3x,
                      https://cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_3088x800.png?v=1614379185 4x
                    "
                  /></a>
                      <p style="font-size:24px;line-height:24px;margin:0px 15px;font-weight:300;color:#888888">Bespoke</p>
                    </td>
            </td>
          </tr>
          </tbody>
        </table>

        <table style="border-collapse:collapse;border-spacing:0px;color:rgb(51,51,51);background-color:rgb(250,250,250);border-radius:3px;font-size:12px;margin: 20px 0;padding: 20px 0;" align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation" width="100%">
          <tbody>
            <tr>
              <td>
                <table width="100%" style="height:46px" align="center" role="presentation" cellSpacing="0" cellPadding="0" border="0">
                  <tbody style="width:100%">
                    <tr style="width:100%">
                      <td >
                        <table width="100%" align="center" role="presentation" cellSpacing="0" cellPadding="0" border="0">
                          <tbody style="width:100%">
                            <tr style="width:100%">
                              <td style="padding-left:20px;height:44px">
                                <p style="font-size:10px;line-height:1.4;margin:0;padding:0;color:rgb(102,102,102)">INVOICE DATE</p>
                                <p style="font-size:12px;line-height:1.4;margin:0;padding:0">${
                                  date.getDate() +
                                  '-' +
                                  (date.getMonth() + 1) +
                                  '-' +
                                  date.getFullYear()
                                }</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%" align="center" role="presentation" cellSpacing="0" cellPadding="0" border="0">
                          <tbody style="width:100%">
                            <tr style="width:100%">
                              <td style="padding-left:20px;height:44px">
                                <p style="font-size:10px;line-height:1.4;margin:0;padding:0;color:rgb(102,102,102)">ORDER ID</p><a target="_blank" style="color:#15c;text-decoration:underline;font-size:12px;margin:0;padding:0;line-height:1.4">${
                                  getLinnworksOrderId?.data.NumOrderId
                                }</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table width="100%" align="center" role="presentation" cellSpacing="0" cellPadding="0" border="0">
                          <tbody style="width:100%">
                            <tr style="width:100%">
                              <td style="padding-left:20px;height:44px">
                                <p style="font-size:10px;line-height:1.4;margin:0;padding:0;color:rgb(102,102,102)">Transaction ID</p><a target="_blank" style="color:#15c;text-decoration:underline;font-size:12px;margin:0;padding:0;line-height:1.4">${
                                  orderCompletedData.purchase_units[0].payments
                                    .captures[0].id
                                }</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td colSpan="2" style="padding:20px;height:44px;border-left: 2px solid white;">
                        <p style="font-size:10px;line-height:1.4;margin:0;padding:0;color:rgb(102,102,102)">SHIPPING TO</p>
                        <p style="font-size:12px;line-height:1.4;margin:0;padding:0">Name: ${
                          customerOrder.firstName + ' ' + customerOrder.lastName
                        }</p>
                        <p style="font-size:12px;line-height:1.4;margin:0;padding:0">Email: ${
                          customerOrder.email
                        }</p>
                        <p style="font-size:12px;line-height:1.4;margin:0;padding:0">Address: ${
                          customerOrder.address
                        }</p>
                        <p style="font-size:12px;line-height:1.4;margin:0;padding:0">City: ${
                          customerOrder.city
                        }</p>
                        <p style="font-size:12px;line-height:1.4;margin:0;padding:0">${
                          'Zip Code: ' + customerOrder.zipCode
                        }</p>
                        <p style="font-size:12px;line-height:1.4;margin:0;padding:0">Country: ${
                          customerOrder.country
                        }</p>
                        <p style="font-size:12px;line-height:1.4;margin:0;padding:0">${
                          customerOrder.phoneNumber &&
                          'Phone Number: ' + customerOrder.phoneNumber
                        }</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <table align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation" width="100%" style="margin: 50px 0;">
          <thead style="background-color:rgb(230, 230, 230);">
            <th width="25%" style="padding: 10px 0;">Item</th>
            <th width="25%" style="padding: 10px 0;">
              Dimensions<br />(Width x Length x Thickness) cm
            </th>
            <th width="25%" style="padding: 10px 0;">Quantity</th>
            <th width="25%" style="padding: 10px 0;">Total</th>
          </thead>



             <tbody>
              ${Array(
                customerOrder.orderDetails
                  .map((order) => {
                    return `<tr >
                <td style="text-align:center; border-bottom:1px solid #a6a6a6;margin: 10px 0;padding: 10px 0;">${
                  order.title
                }</td>
                <td style="text-align:center;border-bottom:1px solid #a6a6a6;margin: 10px 0;padding: 10px 0;">${
                  order.dimensions.width +
                  'x' +
                  order.dimensions.length +
                  'x' +
                  order.dimensions.thickness
                } cm</td>
                <td style="text-align:center;border-bottom:1px solid #a6a6a6;margin: 10px 0;padding: 10px 0;">${
                  order.quantity
                }</td>
                <td style="text-align:center;border-bottom:1px solid #a6a6a6;margin: 10px 0;padding: 10px 0;">£${
                  order.updatedPrice
                }</td>
              </tr>`;
                  })
                  .join('')
              )}
             </tbody>


          <tr>
            <td></td>
            <td></td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">SubTotal</td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">£${
              customerOrder.totalPrice
            }</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">Shipping</td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">£0.00</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">VAT</td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">£${customerOrder.totalVAT.toFixed(
              2
            )}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">Total</td>
            <td style="text-align:center;margin: 10px 0;padding: 10px 0;">£${(
              customerOrder.totalPrice + customerOrder.totalVAT
            ).toFixed(2)}</td>
          </tr>
              </tbody>
        </table>



        <p style="font-size:12px;line-height:auto;margin:50px 0;color:rgb(102,102,102);">**This is computer generated invoice no signature required.</p>

        <table align="center" border="0" cellPadding="0" cellSpacing="0" role="presentation" width="100%">
          <tbody>
            <tr>
              <td>
              <td align="center" style="display:block;margin:40px 0 0 0">
              <a style="cursor: default;"><img width="150px"
                src="https://cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_772x200.png?v=1614379185"
                alt=""
                srcset="
                  //cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_772x200.png?v=1614379185  1x,
                  //cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_1544x400.png?v=1614379185 2x,
                  //cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_2316x600.png?v=1614379185 3x,
                  //cdn.shopify.com/s/files/1/0513/2563/2661/files/Logo_3088x800.png?v=1614379185 4x
                "
                style="display:block;outline:none;border:none;text-decoration:none"
              /></a>
                </td>
              </td>
            </tr>
          </tbody>
        </table>
        <p style="font-size:12px;line-height:24px;margin:8px 0 0 0;text-align:center;color:rgb(102,102,102)"><a target="_blank" style="color:#067df7;text-decoration:none" href="https://gardenistauk.com/">GardenistaUK.com</a> • <a target="_blank" style="color:#067df7;text-decoration:none" href="mailto:CustomOrders@GardenistaUK.com">CustomOrders@GardenistaUK.com</a> • <a target="_blank" style="color:#067df7;text-decoration:none" href="https://gardenistauk.com/policies/terms-of-service">Terms of Service</a> • <span style="color:#067df7;text-decoration:none">0121 232 5075</span></p>
        <p style="font-size:12px;line-height:24px;margin:25px 0 0 0;text-align:center;color:rgb(102,102,102)">Copyright © 2023 GardenistaUK Store.</p>
        </td>
        </tr>
        </table>
      </body>

    </html>
          `,
          };

          emailjs
            .send(
              process.env.REACT_APP_SERVICE_ID,
              process.env.REACT_APP_TEMPLATE_ID,
              templateParams,
              process.env.REACT_APP_PUBLIC_KEY
            )
            .then(
              function (response) {},
              function (error) {}
            );

          setIsLoading(false);

          window.sessionStorage.removeItem('customerDetails');
          window.sessionStorage.removeItem('orders');
          window.sessionStorage.removeItem('orderID');
        };

        linnWorksFunction();
      } catch (error) {
        setIsLoading(false);
      } finally {
      }
    }
  };

  if (error) {
    setError('Approval Error');
  }

  return (
    <div className="max-w-[500px] w-[100%] my-12 mx-auto">
      <PayPalButtons
        style={{
          height: 48,
          label: 'pay',
          shape: 'rect',
        }}
        onClick={(data, actions) => {
          const hasAlreadyBoughtCourse = false;

          if (hasAlreadyBoughtCourse) {
            setError('You already bought this.');

            return actions.reject();
          } else {
            return actions.resolve();
          }
        }}
        createOrder={(data, actions) => {
          return actions.order.create(details_units).then((orderId) => {
            return orderId;
          });
        }}
        onApprove={async (data, actions) => {
          const orderCompletedData = await actions.order.capture();
          window.removeEventListener('beforeunload', beforeUnloadHandler);

          setIsLoading(true);

          if (orderCompletedData.status === 'COMPLETED') {
            handleApprove(orderCompletedData);
          }
        }}
        onCancel={() => {
          navigate('/overview');
          toast.error('Payment cancelled/failed, please try again.');
        }}
        onError={(err) => {
          setError(err);
        }}
      />
    </div>
  );
};

export default Payment;
