import React, { useContext, useEffect, useState } from "react";
import CustomCushionDetails from "../Reuseable/CustomCushionDetails";
import TotalOverview from "../Summary/TotalOverview";
import { Link } from "react-router-dom";
import { projectContext } from "../../Context/projectContext";

const Overview = ({ keyValue }) => {
  const { finalData, setFinalData, setNewCustom } = useContext(projectContext);

  const objectEmpty = () => {
    setNewCustom({
      shape: "",
      filling: "",
      dimensions: {
        width: 0,
        length: 0,
        thickness: 5,
        hasTies: false,
      },
      fabric: "",
      color: "",
      quantity: 1,
      price: "0.00",
    });
  };

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("orders"))) {
      setFinalData(JSON.parse(sessionStorage.getItem("orders")));
    }
  }, [setFinalData]);

  const [selectedOrder, setSelectedOrder] = useState([]);

  return (
    <div className="max-w-[1440px] mx-auto my-0">
      <div className="block lg:flex min-h-[900px]">
      
        <div className="w-full lg:w-[70%] bg-white p-5">
          <p className="text-3xl font-bold text-center lg:text-left font-Lustria text-black">Overview</p>
          <div id="customCushionDetails" className="flex flex-wrap justify-around items-center gap-4 gap-y-8 my-6">
            {finalData.map((a, index) => (
              <CustomCushionDetails
                key={index}
                data={a}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
              />
            ))}

            <Link id="addAnotherCushion" onClick={() => objectEmpty()} to="/step-1" className="min-w-[100%] xsm:min-w-[321px]">
              <div className="border-2 border-[#B0B0B0] rounded-[50px] bg-[#EEEEEE] min-h-[400px] relative flex justify-center items-center">
                <div className="absolute top-[-3%] left-[-3%] bg-white rounded-full">
                  <img
                    src="https://i.ibb.co/wRzwm6S/Add-Order-Button.png"
                    alt="AddOrder"
                  />
                </div>

                <p className="text-2xl font-normal text-center font-Lustria text-black">
                  Add <br /> Another Custom <br /> Cushion
                </p>
              </div>
            </Link>
          </div>
        </div>
        <TotalOverview />
      </div>
    </div>
  );
};

export default Overview;
