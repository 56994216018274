import React from "react";
import { auth } from "../../../firestoreConfig";
import { signOut } from "firebase/auth";

const LogoutModal = () => {

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <div>
      <input
        type="checkbox"
        id="confirmation-logout"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Do you want to Logout?</h3>

          <div className="modal-action">
            <label htmlFor="confirmation-logout">
              <p className="btn" onClick={() => handleLogout()}>
                Yes
              </p>
            </label>
            <label htmlFor="confirmation-logout" className="btn">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
