import React, { useContext } from "react";
import { projectContext } from "../../Context/projectContext";
import {
  plusQuantityChange,
  minusQuantityChange,
} from "../../Helpers/quantityFunctions";

const OrderDesign = ({ data }) => {
  const { finalData, setFinalData, calculationPriceData } =
    useContext(projectContext);

  return (
    <div className="my-4 flex justify-around xl:justify-between">
      <div className="text-black">
        <p className="text-xl font-Lustria">{data.title}</p>
        <p className="capitalize text-sm font-Lustria">
          {data.shape.includes("-")
            ? data.shape.split("-").join(" ")
            : data.shape}
        </p>
        <p className="capitalize text-sm font-Lustria">
          {data.filling.includes("-")
            ? data.filling.split("-").join(" ")
            : data.filling}
        </p>
        <p className="text-sm font-Lustria">
          {data.dimensions.width} W x {data.dimensions.length} L x {data.dimensions.thickness} H cm
        </p>
        <p className="capitalize text-sm font-Lustria">
          {data.fabric}-{data.color}
        </p>
      </div>
      <div className="flex flex-col items-end justify-between text-black">
        <p className="totalPrice text-2xl font-Lustria">
          £{data.updatedPrice ? data.updatedPrice : data.price}
        </p>
        <div className="quantity-part">
          <div className="flex justify-around items-center bg-white max-w-[100%] max-h-[27px] rounded-lg">
            <p
              className="px-2 cursor-pointer border-r-2 border-black text-lg"
              onClick={() =>
                minusQuantityChange(
                  data.order,
                  finalData,
                  setFinalData,
                  calculationPriceData
                )
              }
            >
              -
            </p>
            <p className="mx-2 font-Lustria">
              {data.quantity < 10 ? "0" : ""}
              {data.quantity}
            </p>
            <p
              className="px-2 cursor-pointer border-l-2 border-black text-lg"
              onClick={() =>
                plusQuantityChange(
                  data.order,
                  finalData,
                  setFinalData,
                  calculationPriceData
                )
              }
            >
              +
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDesign;
