import { db } from "./firestoreConfig";
import { toast } from "react-toastify";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  writeBatch,
  orderBy,
  query,
  startAfter,
  limit,
  getCountFromServer,
  where,
} from "firebase/firestore";
import { calculationPrice } from "./Helpers/orderFunctions";

const m2mCollection = "allShapeDB";
const m2mCollectionFilling = "allFilling";
const m2mCollectionPrice = "allPrice";
const m2mCollectionColors = "allColors";
const m2mCollectionFabrics = "allFabrics";
const m2mCollectionOrders = "allOrders";
const m2mCollectionStock = "stockFull";
const m2mCollectionOrdersRef = collection(db, m2mCollectionOrders);

const m2mApiClass = {};

// order action batch update
m2mApiClass.updateOrderDataFromAdmin = async (currentObject) => {
  const batchWrite = writeBatch(db);
  for (let currentID in currentObject) {
    const docRef = doc(db, "allOrders", currentID);
    batchWrite.update(docRef, { deliveryAction: currentObject[currentID] });
  }

  await batchWrite
    .commit()
    .then(() => {
      toast("Action Updated Successfully!");
    })
    .catch((error) => {
      toast.error("Action Updated Failed!");
    });
};

// all order queries.........................

// end all order queries......

// add shape data
m2mApiClass.addShapeData = async (userData) => {
  userData.addingTime = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  await addDoc(collection(db, "allShapeDB"), userData);
};

// add filling data
m2mApiClass.addFillingData = async (userData) => {
  userData.addingTime = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  await addDoc(collection(db, "allFilling"), userData);
};

// add color data
m2mApiClass.addColorData = async (userData) => {
  userData.addingTime = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  await addDoc(collection(db, "allColors"), userData);
};

// add fabrics data
m2mApiClass.addFabricData = async (userData) => {
  userData.addingTime = new Date().toLocaleString("en-GB", {
    timeZone: "Europe/London",
  });
  await addDoc(collection(db, "allFabrics"), userData);
};

// add order data
m2mApiClass.addOrderData = async (userData) => {
  userData.orderDate = new Date().getTime();
  const docRef = await addDoc(collection(db, "allOrders"), userData);
  let x = await m2mApiClass.getPriceData();
  let price;
  let sum = 0;
  let isPriceMatched;
  userData.orderDetails.map((order) => {
    price = calculationPrice(order.dimensions, order.quantity, x);
    sum += Number(price);
    if (
      Number(userData.totalPrice) === Number(sum.toFixed(2)) &&
      parseFloat(order.dimensions.width) >= 15 &&
      parseFloat(order.dimensions.width) <= 120 &&
      parseFloat(order.dimensions.length) >= 15 &&
      parseFloat(order.dimensions.length) <= 200 &&
      (Number(order.dimensions.thickness) === 5 ||
        Number(order.dimensions.thickness) === 7 ||
        Number(order.dimensions.thickness) === 10)
    ) {
      isPriceMatched = "true";
    }
  });
  return { _id: docRef.id, isPriceMatched: isPriceMatched };
};

m2mApiClass.getShapeData = async () => {
  let data = query(collection(db, "allShapeDB"), orderBy("addingTime"));

  const { docs } = await getDocs(data);
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

m2mApiClass.getFillingData = async () => {
  let data = query(collection(db, "allFilling"), orderBy("addingTime"));

  const { docs } = await getDocs(data);
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

m2mApiClass.getPriceData = async () => {
  const { docs } = await getDocs(collection(db, "allPrice"));
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

m2mApiClass.getColorData = async () => {
  let data = query(collection(db, "allColors"), orderBy("addingTime"));
  const { docs } = await getDocs(data);
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

m2mApiClass.getStockData = async () => {
  const { docs } = await getDocs(collection(db, "stockFull"));
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

m2mApiClass.getFabricData = async () => {
  let data = query(collection(db, "allFabrics"), orderBy("addingTime"));
  const { docs } = await getDocs(data);
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

m2mApiClass.getOrderDataLength = async (selectedQuery) => {
  const snapshot = await getCountFromServer(selectedQuery);
  return snapshot.data().count;
};

// get the last Item(Use in frontend)
m2mApiClass.getOrderDataLastItem = async () => {
  // sort the collection
  const sortedCollectionQuery = query(
    m2mCollectionOrdersRef,
    orderBy("orderID", "desc"),
    limit(1)
  );

  // get the last ID
  const { docs } = await getDocs(sortedCollectionQuery);
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

//Finalize.............................................

// filtered Query
function buildQuery(queryValues, pageSize) {
  let q;
  if (queryValues.startedDate === false || queryValues.startedDate === null) {
    queryValues.startedDate = new Date("01-01-2023").getTime();
  }
  if (queryValues.endedDate === false || queryValues.endedDate === null) {
    queryValues.endedDate = new Date().getTime();
  }

  switch (true) {
    case queryValues.progress === null && queryValues.status === null:
      q = query(
        m2mCollectionOrdersRef,
        where("orderDate", ">=", queryValues.startedDate),
        where("orderDate", "<=", queryValues.endedDate)
      );
      break;
    case queryValues.progress !== null && queryValues.status !== null:
      q = query(
        m2mCollectionOrdersRef,
        where("deliveryAction", "==", queryValues.progress),
        where("payment", "==", queryValues.status),
        where("orderDate", ">=", queryValues.startedDate),
        where("orderDate", "<=", queryValues.endedDate)
      );
      break;
    case queryValues.progress !== null && queryValues.status === null:
      q = query(
        m2mCollectionOrdersRef,
        where("deliveryAction", "==", queryValues.progress),
        where("orderDate", ">=", queryValues.startedDate),
        where("orderDate", "<=", queryValues.endedDate)
      );
      break;
    case queryValues.progress === null && queryValues.status !== null:
      q = query(
        m2mCollectionOrdersRef,
        where("payment", "==", queryValues.status),
        where("orderDate", ">=", queryValues.startedDate),
        where("orderDate", "<=", queryValues.endedDate)
      );
      break;
    default:
      break;
  }

  return q;
}
// For All Data
m2mApiClass.getOrderData = async (
  pageSize,
  FilteredValue,
  firstVisible,
  setFirstVisible,
  lastVisible,
  setLastVisible,
  type
) => {
  let q = buildQuery(FilteredValue, pageSize);
  const allfetchedOrdersLength = await m2mApiClass.getOrderDataLength(q);

  if (!type) {
    q = query(q, orderBy("orderDate", "desc"), limit(pageSize));
  } else if (type === "next") {
    q = query(
      q,
      orderBy("orderDate", "desc"),
      startAfter(lastVisible),
      limit(pageSize)
    );
  }

  const { docs } = await getDocs(q);
  setFirstVisible(docs[0]);
  setLastVisible(docs[docs.length - 1]);
  const docData = docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });

  // Full length of query
  return { docData, allfetchedOrdersLength };
};

// get single User
m2mApiClass.getSucceedUser = async (singleOrderID) => {
  const customer = query(
    m2mCollectionOrdersRef,
    where("orderID", "==", singleOrderID),
    limit(1)
  );

  const { docs } = await getDocs(customer);
  return docs.map((doc) => {
    return { ...doc.data(), _id: doc.id };
  });
};

// For Filtered Data

m2mApiClass.getFilteredOrderData = async (queryValues) => {
  const q = buildQuery(queryValues);

  const querySnapshot = await getDocs(q);
  const documents = querySnapshot.docs.map((doc) => ({
    _id: doc.id,
    ...doc.data(),
  }));
  return documents;
};

// update data
m2mApiClass.updateData = async (_id, currentData) => {
  const updatedShapeDoc = doc(db, m2mCollection, _id);
  return updateDoc(updatedShapeDoc, currentData);
};

m2mApiClass.updateFillingData = async (_id, currentData) => {
  const updatedShapeDoc = doc(db, m2mCollectionFilling, _id);
  return updateDoc(updatedShapeDoc, currentData);
};

m2mApiClass.updatePriceData = async (_id, currentData) => {
  const updatedPriceDoc = doc(db, m2mCollectionPrice, _id);
  return updateDoc(updatedPriceDoc, currentData);
};

m2mApiClass.updateOrderData = async (_id, currentData) => {
  const updatedOrderDoc = doc(db, m2mCollectionOrders, _id);
  return updateDoc(updatedOrderDoc, currentData);
};

m2mApiClass.updateStockFull = async (_id, currentData) => {
  const updatedStockDoc = doc(db, m2mCollectionStock, _id);
  return updateDoc(updatedStockDoc, currentData);
};

// batch update

//  delete data
m2mApiClass.deleteData = async (_id) => {
  const selectedDoc = doc(db, m2mCollection, _id);
  await deleteDoc(selectedDoc);
};

m2mApiClass.deleteFillingData = async (_id) => {
  const selectedDoc = doc(db, m2mCollectionFilling, _id);
  await deleteDoc(selectedDoc);
};

m2mApiClass.deleteColorData = async (_id) => {
  const selectedDoc = doc(db, m2mCollectionColors, _id);
  await deleteDoc(selectedDoc);
};

m2mApiClass.deleteFabricData = async (_id) => {
  const selectedDoc = doc(db, m2mCollectionFabrics, _id);
  await deleteDoc(selectedDoc);
};

m2mApiClass.deleteOrderData = async (_id) => {
  const selectedDoc = doc(db, m2mCollectionOrders, _id);
  await deleteDoc(selectedDoc);
};

export default m2mApiClass;
