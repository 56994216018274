import React, { useContext, useEffect } from 'react';
import { projectContext } from '../../Context/projectContext';

const ThankYou = () => {
  const { isLoading } = useContext(projectContext);

  function beforeUnloadHandler(e) {
    let confirmationMessage = 'Leave Site? Changes you made may not be saved.';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }
  useEffect(() => {
    if (isLoading) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [isLoading]);

  return (
    <div className="flex flex-col justify-center items-center h-[80vh]">
      {isLoading ? (
        <>
          <button
            type="button"
            disabled
            className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-2 rounded border border-blue-700 bg-[#83a478] px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none"
          >
            <svg className="h-6 w-6 animate-spin" viewBox="3 3 18 18">
              <path
                className="fill-blue-800"
                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
              ></path>
              <path
                className="fill-blue-100"
                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
              ></path>
            </svg>
            <span className="text-2xl">Processing...</span>
          </button>
        </>
      ) : (
        <>
          <p className="text-4xl text-green-400 my-16">Thank You!</p>
          <p className="max-w-[500px] w-[100%] text-2xl text-center">
            Payment successful, thank you for your order. We have sent you an
            invoice to your email.
          </p>
        </>
      )}
    </div>
  );
};

export default ThankYou;
