import { toast } from "react-toastify";

function generateRandomId(length) {
  let result = "";
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
// Final Order Selection Function
export const selectedFinalOrder = (finalData, newCustom) => {
  let data = {
    order: generateRandomId(16),
    title: `Custom Cushion ${finalData.length + 1}`,
    shape: newCustom.shape,
    filling: newCustom.filling,
    dimensions: newCustom.dimensions,
    fabric: newCustom.fabric,
    color: newCustom.color,
    quantity: newCustom.quantity,
    price: newCustom.price,
    updatedPrice: newCustom.price,
    updatedVAT: Number((newCustom.price * 0.2).toFixed(2)),
  };

  window.scrollTo(0, 0);
  window.sessionStorage.setItem("orders", JSON.stringify([...finalData, data]));
  toast.success("Custom Cushion Added!", {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

// Order Delete Function
export const deleteOrder = (id, setFinalData) => {
  const updatedData = JSON.parse(sessionStorage.getItem("orders")).filter(
    (a) => a.order !== id
  );

  updatedData.map((a, index) => {
    a.order = index + 1;
    a.title = `Custom Cushion ${index + 1}`;
  });

  setFinalData(updatedData);
  window.sessionStorage.setItem("orders", JSON.stringify(updatedData));
  toast.success("Custom Cushion Deleted!", {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

// Order Edit Function
export const editOrder = (id, setNewCustom, navigate) => {
  navigate("/step-1");
  let selectedOrder = JSON.parse(sessionStorage.getItem("orders")).find(
    (a) => a.order === id
  );

  setNewCustom({
    shape: selectedOrder.shape,
    filling: selectedOrder.filling,
    dimensions: selectedOrder.dimensions,
    fabric: selectedOrder.fabric,
    color: selectedOrder.color,
    order: selectedOrder.order,
    quantity: selectedOrder.quantity,
    price: selectedOrder.price,
    updatedPrice: selectedOrder.updatedPrice,
    updatedVAT: selectedOrder.updatedVAT,
  });
};

export const calculationPrice = (
  dimensions,
  quantity,
  calculationPriceData
) => {
  let price;
  let thicknessPrice;
  let priceWithQuantity;

  const { length, width, thickness } = dimensions;

  calculationPriceData.map((data) => {
    for (const key in data.foamThickness) {
      if (key === `t${Number(thickness)}`) {
        thicknessPrice = data.foamThickness[key];
      }
    }

    let patternCost = Number((data.patternCost * 1).toFixed(2));
    let fabricCost = Number(
      ((Number(length) / 100) * data.fabricCost * quantity).toFixed(2)
    );

    let cuttingCost = Number((data.cuttingCost * quantity).toFixed(2));
    let sewingCost = Number((data.sewingCost * quantity).toFixed(2));
    let accsCost = Number((data.accsCost * quantity).toFixed(2));
    let fillingLabourCost = Number(
      (data.fillingLabourCost * quantity).toFixed(2)
    );
    let packagingCost = Number((data.packagingCost * quantity).toFixed(2));
    let courierCost = Number((data.courierCost * quantity).toFixed(2));
    let overHeadCost = Number((data.overHeadCost * quantity).toFixed(2));

    let foamCost = Number(
      (
        (Number(length) + Number(width) + Number(thickness)) *
        thicknessPrice *
        quantity
      ).toFixed(2)
    );

    priceWithQuantity =
      (cuttingCost +
        sewingCost +
        accsCost +
        fillingLabourCost +
        packagingCost +
        courierCost +
        overHeadCost) *
      data.markUp;

    price = Number(
      (
        (patternCost + fabricCost + foamCost) * data.markUp +
        priceWithQuantity
      ).toFixed(2)
    );
  });

  return Math.round(price) - 0.03;
};

// Edited order replace function
export const editedOrder = (
  data,
  id,
  finalData,
  setFinalData,
  calculationPriceData
) => {
  window.scrollTo(0, 0);
  let updatedObjectIndex = finalData.findIndex((obj) => obj.order === id);

  finalData[updatedObjectIndex] = {
    order: id,
    title: `Custom Cushion ${updatedObjectIndex + 1}`,
    shape: data.shape,
    filling: data.filling,
    dimensions: data.dimensions,
    fabric: data.fabric,
    color: data.color,
    quantity: data.quantity,
    price: calculationPrice(data.dimensions, 1, calculationPriceData),
    updatedPrice: calculationPrice(
      data.dimensions,
      data.quantity,
      calculationPriceData
    ),
    updatedVAT: (
      calculationPrice(data.dimensions, data.quantity, calculationPriceData) *
      0.2
    ).toFixed(2),
  };
  setFinalData(finalData);
  window.sessionStorage.setItem("orders", JSON.stringify(finalData));
  toast.success("Custom Cushion Edited!", {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};
