import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { projectContext } from "../../Context/projectContext";
import ColorDesign from "../Reuseable/ColorDesign";
import FabricDesign from "../Reuseable/FabricDesign";
import RedirectButtons from "../Reuseable/RedirectButtons";
import Summary from "../Summary/Steppers";
import m2mApiClass from "../../firestoreBackendAPI";
import Loading from "../../Shared/Loading";

const FabricWithColour = ({ step, keyValue }) => {
  const {
    fabricType,
    colorData,
    newCustom,
    setNewCustom,
    setColorData,
    setFabricType,
    isLoading,
    setIsLoading,
  } = useContext(projectContext);
  const [selectedFabric, setSelectedFabric] = useState(newCustom.fabric);
  const [selectedColor, setSelectedColor] = useState(newCustom.color);
  const [redirect, setRedirect] = useState(false);
  let navigate = useNavigate();

  function handleFabricChange(event) {
    const clickedButton = event.target.value;
    setSelectedFabric(clickedButton);
    setNewCustom((prevState) => ({
      ...prevState,
      fabric: clickedButton,
    }));
  }

  function handleColorChange(event) {
    const clickedButton = event.target.value;
    setSelectedColor(clickedButton);
    setNewCustom((prevState) => ({
      ...prevState,
      color: clickedButton,
    }));
  }

  const fetchData = async () => {
    const allfetchedColor = await m2mApiClass.getColorData();
    setColorData(allfetchedColor);
    const allfetchedfabric = await m2mApiClass.getFabricData();
    setFabricType(allfetchedfabric);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);


  useEffect(() => {
    if (newCustom.color !== "" && newCustom.fabric !== "") {
      setRedirect(true);
    }
    window.addEventListener("load", () => {
      navigate("/step-1");
    });
  }, [newCustom.color, newCustom.fabric, navigate]);

  return (
    <div className="max-w-[1440px] mx-auto my-0">
      <div className="block lg:flex">
        <div className="w-full lg:w-[70%] bg-white p-5 flex flex-col justify-between">
          <div id={keyValue} className="max-w-[100%]">
            <div className="block md:flex justify-between">
              <div>
                <p className="text-xl lg:text-3xl text-black">
                  Select Fabric Type & Colour
                </p>
                <p className="mb-6 text-lg lg:text-xl font-Lustria text-black">
                  Step 4
                </p>
              </div>

              <div id="ideaPart2" className="flex justify-end items-start">
                <div className="mt-[-30px] mr-[-20px] z-[2]">
                  <img
                    src="https://i.ibb.co/6DCL0DZ/Light-Bulb.png"
                    alt="lightBulb"
                  />
                </div>
                <div className="max-w-[234px] bg-[#F6F6F6] rounded-xl">
                  <p className="text-[13px] leading-[17px] p-[10px] text-center font-Lustria text-black">
                    All our fabrics are water resistant, durable and suitable
                    for all year round. They are also easy to clean and stains
                    can be cleaned off with a damp cloth or machine wash
                  </p>
                </div>
              </div>
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <div>
                <div id="fabricPart">
                  <p className="text-black text-lg my-6 font-Lustria text-black">
                    Fabric Type
                  </p>
                  <div className="flex gap-12 flex-wrap">
                    {fabricType.map((fabric, index) => (
                      <FabricDesign
                        key={fabric.id}
                        selectedFabric={selectedFabric}
                        fabric={fabric}
                        handleFabricChange={handleFabricChange}
                      />
                    ))}
                  </div>
                </div>
                <div id="colorPart">
                  <p className="text-black text-lg my-6 font-Lustria text-black">
                    Colour
                  </p>
                  <div className="flex gap-12 flex-wrap justify-start">
                    {colorData.map((color, index) => (
                      <ColorDesign
                        key={color.id}
                        selectedColor={selectedColor}
                        color={color}
                        handleColorChange={handleColorChange}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <RedirectButtons
            step={step}
            redirect={redirect}
            newCustom={newCustom}
            productPrice={newCustom.price}
          />
        </div>
        <Summary step={step} productPrice={newCustom.price} data={null} />
      </div>
    </div>
  );
};

export default FabricWithColour;
